import {
    SET_PLAN,
    SET_LINK,
    SET_METHOD,
} from '../actions/purchase-actions';

export default function purchaseReducer(state = '', { type, payload}) {
    switch (type) {
    case SET_LINK:
        return {
            ...state,
            link: payload
        };
    case SET_PLAN:
        return {
            ...state,
            plan: payload
        };
    case SET_METHOD:
        return {
            ...state,
            method: payload
        };
    default:
        return state;
    }
}
