import './style.css';
import React from 'react';

import Menu1 from './imgs/menu-1.png';
import Menu2 from './imgs/menu-2.png';
import Menu3 from './imgs/menu-3.png';
import {addHours, formatISO} from 'date-fns';
import {bindActionCreators} from 'redux';
import {setPurchaseOption} from '../../actions/purchase-actions';
import {connect} from 'react-redux';

const mapStateToProps = state => ({
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        setPlan: setPurchaseOption
    }, dispatch);
};

const Home = (props) => {
    return (
        <div className="menu menu-content">
            <span className={'menu-title'}>
                Emissão de Nota Fiscal
            </span>
            <span className={'menu-sub-title'}>
                    Escolha quantas notas precisará emitir e faça todo o processo de forma simples.
                    Pague usando cartão de crédito ou boleto bancário!
            </span>

            <div className="menu-cards">
                <div className="card" onClick={() => {
                    props.setPlan(1);
                    props.handleNextStep();
                }}>
                    <img className="menu-image" src={Menu1} alt={''}/>
                    <div className={'card-description'}>
                        <span className="card-title">Até 5 Notas Fiscais (R$15,00)</span>
                        <span className="card-sub">Emita até 5 notas fiscais pagando apenas R$15,00 pelas emissões</span>
                        <button className={'card-button'}>
                                    Iniciar emissão
                        </button>
                    </div>
                </div>
                <div className="card" onClick={() => {
                    props.setPlan(2);
                    props.handleNextStep();
                }}>
                    <img className="menu-image" src={Menu2} alt={''}/>
                    <div className={'card-description'}>
                        <span className="card-title">Até 30 Notas Fiscais (R$30,00)</span>
                        <span className="card-sub">Emita até 30 notas fiscais pagando apenas R$30,00 pelas emissões</span>
                        <button className={'card-button'}>
                                Iniciar emissão
                        </button>
                    </div>
                </div>
                <div className="card" onClick={() => props.handleStepBack(8)}>
                    <img className="menu-image" src={Menu3}/>
                    <div className={'card-description'}>
                        <span className="card-title">Minhas notas emitidas</span>
                        <span className="card-sub">Lista completa do seu historico de notas</span>
                        <button className={'card-button'}>
                                Ver emissões
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Home);

