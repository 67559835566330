import React from 'react';
import ReactDOM from 'react-dom';
import App from './AppContainer';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/browser';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import ReactNotification from 'react-notifications-component';
import ReactGA from 'react-ga';

import './index.css';

import 'animate.css/animate.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';

ReactGA.initialize('UA-112487562-3', {
    gaOptions: {
        cookieFlags: 'SameSite=None;Secure'
    }
});

Sentry.init({dsn: "https://8d45efa469f8461aab28a607ed950cee@o377285.ingest.sentry.io/5266137"});

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={''}>
            <ReactNotification />
            <App />
        </PersistGate>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
