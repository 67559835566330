import './styles.css';
import React, {useEffect, useState} from 'react';
import StatusBar from '../../Status-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    saveServico,
    saveDescricao,
    saveAtividade
} from '../../../actions/serviceData-actions';
import {fetchLC116List} from '../../../api';

const mapStateToProps = state => ({
    servico: state.serviceData.servico,
    atividade: state.serviceData.atividade,
    descricao: state.serviceData.descricao,
    atividades: state.companyData.atividades
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveServico: saveServico,
        saveAtividade: saveAtividade,
        saveDescricao: saveDescricao
    }, dispatch);
};

const componentStyles = {
    nextButton: {
        width: '223px',
        height: '58px',
        padding: '1em',
        marginLeft: '3em',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '50px',
        backgroundColor: '#e1173f'
    },
    alignInputs: {
        textAlign: '-webkit-center'
    },
};

const Servico = (props) => {

    const [listaDescricoes, setListaDescricoes] = useState([]);

    const [loadingActivity, setLoadingActivity] = useState(false);

    useEffect(() => {
        handleAtividade(props.atividades[0].codigo);
    }, []);

    const handleAtividade = async (value) => {
        props.saveAtividade(value);
        setLoadingActivity(true);
        await fetchLC116List(value).then(
            (response) => {
                setListaDescricoes(response.data.lc116);
                handleLC116(response.data.lc116[0].codigo);
                setLoadingActivity(false);
            }
        ).catch(
            (err) => console.log(err)
        );
    };

    const handleLC116 = (value) => {
        props.saveServico(value);
    };

    const handledDescricao = (value) => {
        props.saveDescricao(value);
    };

    const handleSubmit = () => {
        if (loadingActivity) return;
        if (props.atividade === '' || props.servico === '') {
            props.setFeedBackType('warning');
            props.setFeedBackMessage('Você precisa selecionar um Serviço e uma Atividade para continuar');
        } else {
            props.handleNextStep();
        }
    };

    return (
        <div className={'content'} style={{height: '50em', width: '101%'}}>
            <StatusBar currentStatus={7} />

            <span className="head-title-about" style={{color: '#000000'}}>
                <FontAwesomeIcon icon={faChevronLeft} className="head-icon-about"/>
                    Sobre o serviço
            </span>
            <span className="header-sub-about">Detalhe sobre o serviço que você prestou</span>

            <div className="row">
                <div className="col-md-3"/>
                <div
                    style={componentStyles.alignInputs}
                    className="col-md-6 form-input-group-about"
                >
                    <select
                        value={props.atividade}
                        style={componentStyles.customSelect}
                        className="form-control custom_select_option"
                        onChange={(e) => handleAtividade(e.target.value)}
                    >
                        {
                            (props.atividades.length > 0 ? (
                                props.atividades.map((item, key) => (
                                    <option value={item.codigo} key={key}>{item.descricao}</option>
                                ))
                            ) : (
                                <option value={null}>
                                    {'Selecione a atividade'}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-md-3"/>
            </div>
            <div className="row">
                <div className="col-md-3"/>
                <div
                    style={componentStyles.alignInputs}
                    className="col-md-6 form-input-group-about"
                >
                    <select
                        value={props.lc116}
                        style={componentStyles.customSelect}
                        disabled={listaDescricoes.length === 0}
                        className="form-control custom_select_option"
                        onChange={(e) => handleLC116(e.target.value)}
                    >
                        {
                            (listaDescricoes.length > 0 ? (
                                listaDescricoes.map((item, key) => (
                                    <option
                                        key={key}
                                        value={item.codigo}>
                                        {item.descricao}
                                    </option>
                                ))
                            ) : (
                                <option value={null}>
                                    {'Selecione o serviço'}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="col-md-3"/>
            </div>
            <div className="row">
                <div className="col-md-3"/>
                <div
                    style={componentStyles.alignInputs}
                    className="col-md-6 form-input-group-about"
                >
                    <textarea
                        value={props.descricao}
                        placeholder="Descrição do serviço"
                        className="form-control custom-input social-input-about custom-textarea-about"
                        onChange={(e) => handledDescricao(e.target.value)}
                    />
                </div>
                <div className="col-md-3"/>
            </div>

            <div className="footer-buttons-about">
                <div
                    className="back-button-about"
                    onClick={() => props.handleStepBack()}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className="back-icon-about"/>
                    <span className="back-text">Voltar</span>
                </div>
                <div
                    style={componentStyles.nextButton}
                    onClick={() => handleSubmit()}
                >
                    <span className="custom-button-about">Proximo passo</span>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Servico);
