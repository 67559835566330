import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import StatusBar from '../../Status-bar';

import {bindActionCreators} from 'redux';
import {
    saveCredentialType,
    saveCredentialLogin,
    saveCredentialAttachmentData,
    saveCredentialToken, saveCredentialPassword
} from '../../../actions/credential-actions';

import './style.css';
import Clip from '../../../assets/clip.png';

import {sendCredentialFile} from '../../../api';

const componentStyles = {
    header: {

    },
    alignment: {
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        width: '320px',
        height: '27px',
        fontSize: '22px',
        marginTop: '2em',
        color: '#000000',
        lineHeight: '0.91',
        fontWeight: 'bold',
        textAlign: 'center',
        alignSelf: 'center',
        marginBottom: '1.5em',
        fontFamily: 'Montserrat, cursive',
    },
    credentialInfo: {
        width: '596px',
        height: '22px',
        fontSize: '18px',
        color: '#000000',
        fontWeight: '500',
        lineHeight: '1.22',
        textAlign: 'center',
        objectFit: 'contain',
        fontFamily: 'Montserrat',
    },
    input: {
        width: '20em',
        placeContent: 'center',
        textAlign: '-webkit-center',
    },
    customInput: {
        width: '20em',
        height: '50px',
        padding: '1em',
        margin: '1em 0',
    },
    secondaryButton: {
        width: '223px',
        height: '58px',
        padding: '1em',
        textAlign: 'center',
        alignSelf: 'center',
        borderRadius: '50px',
        textDecoration: 'none',
        border: 'solid 1px #e1173f',
    },
    secondaryButtonText: {
        color: '#e1173f',
        textDecoration: 'none'
    },
    nextButton: {
        width: '223px',
        height: '58px',
        padding: '1em',
        cursor: 'pointer',
        textAlign: 'center',
        alignSelf: 'center',
        borderRadius: '50px',
        textDecoration: 'none',
        backgroundColor: '#e1173f'
    },
    buttonText: {
        color: '#ffffff',
        textDecoration: 'none'
    },
    backButton: {
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#3b69ff',
        fontSize: '16px',
        cursor: 'pointer',
        textAlign: 'left',
        fontWeight: '300',
        lineHeight: '1.19',
        fontFamily: 'Lato',
        textDecorationLine: 'none',
    },
    footerButtons: {
        display: 'flex',
        marginTop: '1.4rem',
        alignSelf: 'center',
        flexDirection: 'row',
    }
};

const fileInputStyles = {
    attachmentIcon: {
        width: '40px',
        margin: '4px',
        float: 'right',
        height: '40px',
        borderRadius: '4px',
        backgroundColor: '#e1173f',
    }
};

const Credencial = (props) => {

    const [credentialType, setCredentialType] = useState(0);

    const [fileName, setFileName] = useState('Upload de certificado');

    useEffect(() => {
        if (props.credentials.loginType) {
            setCredentialType(props.credentials.loginType);
        }
    }, []);

    const handleLoginChange = (value) => {
        if (value !== '') {
            props.setLogin(value);
        }
    };

    const handlePasswordChange = (value) => {
        if (value !== '') {
            props.setPassword(value);
        }
    };

    const handleFileChange = () => {
        const fileInput = document.getElementById('file');
        if (fileInput.files.length > 0) {
            const cnpj = props.currentCnpj.match(/\d+/g).join('');
            const fileExtension = fileInput.value.split('.').pop();
            setFileName(`${cnpj}.${fileExtension}`);
            props.setAttachment(fileInput.files[0]);
        } else {
            props.setAttachment(null);
            setFileName('Upload de certificado');
        }
    };

    const handleFileSubmit = () => {
        if (props.credentials.attachmentData !== '') {
            sendCredentialFile().then(
                (response) => {
                    props.handleNextStep();
                }
            ).catch(
                (err) => console.log(err)
            );
        }
    };

    const handleSubmit = () => {
        props.setFeedBackType('');
        props.setFeedBackMessage('');
        if (credentialType === 2) {
            if (props.credentials.login === '' || props.credentials.password === '') {
                props.setFeedBackType('warning');
                props.setFeedBackMessage('Os campos Login e Senha não podem ser vazios');
            } else {
                props.handleNextStep();
            }
        }
        else if (credentialType === 1) {
            if (fileName === 'Upload de certificado') {
                props.setFeedBackMessage('warning');
                props.setFeedBackMessage('O certificado é necessario para prosseguir');
            }
            else if (props.credentials.password === '') {
                props.setFeedBackMessage('warning');
                props.setFeedBackMessage('Você deve informar a senha do certificado para continuar');
            } else {
                handleFileSubmit();
            }
        }
        else if (credentialType === 3) {
            if (props.credentials.token === '') {
                props.setFeedBackType('warning');
                props.setFeedBackMessage('O campo Token não pode ser vazio');
            } else {
                props.handleNextStep();
            }
        }
    };

    return (
        <div className="content" style={{marginBottom: '20px'}}>
            {(credentialType !== 0) && <StatusBar currentStatus={5} />}

            {(credentialType === 0) && (
                <div style={componentStyles.alignment}>
                    <span style={componentStyles.title}>
                        Cidade não habilitada
                    </span>
                    <span className="header-sub-not-available mb-4">Nosso serviço ainda não habilitou sua cidade para emissão de notas.</span>
                    <span className="header-sub-not-available">Assim que liberarmos sua cidade informaremos por email.</span>
                    <span className="header-sub-not-available">Desculpe!</span>
                    <div
                        style={{
                            ...componentStyles.nextButton,
                            marginTop: '2em'
                        }}
                        onClick={() => props.handleStepBack(0)}
                    >
                        <span style={componentStyles.buttonText}>Voltar a home</span>
                    </div>
                </div>
            )}

            {(credentialType === 1) && (
                <div style={componentStyles.alignment}>
                    <div className={'row'}>
                        <div className="col-md-3"/>
                        <div className={'col-md-6'} style={componentStyles.alignment}>
                            <span style={componentStyles.title}>
                                <FontAwesomeIcon
                                    icon={faChevronLeft}
                                    onClick={() => props.handleStepBack()}
                                    className="head-icon-attachment"
                                />
                                Credenciais de acesso
                            </span>
                            <span className="header-sub-attachment">Informe seus dados de acesso ao site da prefeitura da sua cidade:</span>
                        </div>
                        <div className="col-md-3"/>
                    </div>
                    <div className="row mb-5">
                        <div className="col-md-3"/>
                        <div className="col-md-6 attachment-input-group attachment-inputs-group-custom">
                            <input
                                id="file"
                                type="file"
                                name="file"
                                style={{display: 'none'}}
                                onChange={() => handleFileChange()}
                            />
                            <label className="attachment-custom-input attachment-file-input" htmlFor="file">
                                <span className="attachment-file-input-text">
                                    <span id="fileLabel">{fileName}</span>
                                </span>
                                <span style={fileInputStyles.attachmentIcon}>
                                    <img src={Clip} height="24" width="24" className="attachment-upload-icon"  alt={''}/>
                                </span>
                            </label>
                            <input
                                type="password"
                                placeholder="Senha"
                                style={{padding: '1em'}}
                                className="attachment-custom-input attachment-token-input"
                                onChange={(e) => handlePasswordChange(e.target.value)}
                            />
                        </div>
                        <div className="col-md-3"/>
                    </div>
                </div>
            )}

            {(credentialType === 2) && (
                <div style={componentStyles.alignment}>
                    <div className={'row'}>
                        <div className={'col-md-4'}/>
                        <div className={'col-md-4'} style={componentStyles.alignment}>
                            <span style={componentStyles.title}
                                onClick={() => props.handleStepBack()}
                            >
                                <FontAwesomeIcon icon={faChevronLeft} className="head-icon-login"/>
                                Credenciais de acesso
                            </span>
                            <span className="header-sub-login">Informe seus dados de acesso ao site da prefeitura da sua cidade</span>
                        </div>
                        <div className={'col-md-4'}/>
                    </div>

                    <div className="row inputs-login">
                        <div className="col-md-4"/>
                        <div className="col-md-4 mt-4">
                            <input
                                type="text"
                                value={props.credentials.login}
                                style={componentStyles.customInput}
                                className="form-control custom-input"
                                placeholder="Usuario de acesso ao site da prefeitura"
                                onChange={(e) => handleLoginChange(e.target.value)}
                            />
                            <input
                                type="password"
                                value={props.credentials.password}
                                style={componentStyles.customInput}
                                className="form-control custom-input"
                                placeholder="Senha de acesso ao site da prefeitura"
                                onChange={(e) => handlePasswordChange(e.target.value)}
                            />
                        </div>
                        <div className="col-md-4"/>
                    </div>
                </div>
            )}

            {(credentialType === 3) && (
                <div className={'token-login-form'} id={'only-token'} >
                    <span className="head-title-login"
                        onClick={() => props.handleStepBack()}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="head-icon-login"/>
                    Credenciais de acesso
                    </span>
                    <span className="header-sub-login">Informe seus dados de acesso ao site da prefeitura da sua cidade</span>

                    <div className="inputs-login">
                        <div className="col-md-5"/>
                        <div className="col-md-2 login-input-group-token">
                            <input
                                type="text"
                                value={props.credentials.token}
                                placeholder="Token site da prefeitura"
                                className="form-control custom-input-login"
                                onChange={(e) => props.setToken(e.target.value)}
                            />
                        </div>
                        <div className="col-md-5"/>
                    </div>
                </div>
            )}

            {(credentialType !== 0) && (
                <div className="attachment-footer-buttons">
                    <div
                        style={{
                            ...componentStyles.backButton,
                            marginRight: '1em'
                        }}
                    >
                        <div
                            className="attachment-back-button"
                            onClick={() => props.handleStepBack()}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} className="attachment-back-icon"/>
                            <span>Voltar</span>
                        </div>
                    </div>
                    {(credentialType === 1 || props.credentials.credentialType === 2) && (
                        <a
                            target={'_blank'}
                            rel="noopener noreferrer"
                            style={{
                                ...componentStyles.secondaryButton,
                                marginRight: '1em'
                            }}
                            href={'https://serasa.certificadodigital.com.br'}>
                            <span
                                style={componentStyles.secondaryButtonText}
                            >
                                Não tenho certificado</span>
                        </a>
                    )}
                    <div
                        style={componentStyles.nextButton}
                        onClick={() => handleSubmit()}
                    >
                        <span style={componentStyles.buttonText}>Proximo passo</span>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    credentials: state.credentials,
    currentCnpj: state.companyData.cnpj
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        setLogin: saveCredentialLogin,
        setToken: saveCredentialToken,
        setPassword: saveCredentialPassword,
        setAttachment: saveCredentialAttachmentData,
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(Credencial);
