import {
    SAVE_COMPANY_SMU,
    SAVE_COMPANY_CNPJ,
    SAVE_COMPANY_CNAE,
    SAVE_COMPANY_EMAIL,
    SAVE_COMPANY_LC116,
    SAVE_COMPANY_ESTADO,
    SAVE_COMPANY_CIDADE,
    SAVE_COMPANY_TELEFONE,
    SAVE_COMPANY_ATIVIDADE,
    SAVE_COMPANY_RAZAO_SOCIAl,
    SAVE_COMPANY_INSC_ESTADUAL,
    SAVE_COMPANY_NOME_FANTASIA,
    SAVE_COMPANY_LISTA_REGIMES,
    SAVE_COMPANY_INSC_MUNICIPAL,
    SAVE_COMPANY_REGIME_ESPECIAL,
    SAVE_COMPANY_LISTA_ATIVIDADES
} from '../actions/companyData-actions';
import {cnpj} from 'cpf-cnpj-validator';

export default function companyDataReducer(state = '', { type, payload }) {
    switch (type) {
    case SAVE_COMPANY_CNAE:
        return {
            ...state,
            cnae: payload.cnae
        };
    case SAVE_COMPANY_SMU:
        return {
            ...state,
            smu: payload.smu
        };
    case SAVE_COMPANY_CNPJ:
        return {
            ...state,
            cnpj: payload.cnpj
        };
    case SAVE_COMPANY_EMAIL:
        return {
            ...state,
            email: payload.email
        };
    case SAVE_COMPANY_LC116:
        return {
            ...state,
            lc116: payload.lc116
        };
    case SAVE_COMPANY_ESTADO:
        return {
            ...state,
            estado: payload.estado
        };
    case SAVE_COMPANY_CIDADE:
        return {
            ...state,
            cidade: payload.cidade
        };
    case SAVE_COMPANY_TELEFONE:
        return {
            ...state,
            telefone: payload.telefone
        };
    case SAVE_COMPANY_ATIVIDADE:
        return {
            ...state,
            atividade: payload.atividade
        };
    case SAVE_COMPANY_RAZAO_SOCIAl:
        return {
            ...state,
            razaoSocial: payload.razaoSocial
        };
    case SAVE_COMPANY_LISTA_REGIMES:
        return {
            ...state,
            regimes: payload.regimes
        };
    case SAVE_COMPANY_INSC_ESTADUAL:
        return {
            ...state,
            inscEstadual: payload.inscEstadual
        };
    case SAVE_COMPANY_NOME_FANTASIA:
        return {
            ...state,
            nomeFantasia: payload.nomeFantasia
        };
    case SAVE_COMPANY_INSC_MUNICIPAL:
        return {
            ...state,
            inscMunicipal: payload.inscMunicipal
        };
    case SAVE_COMPANY_REGIME_ESPECIAL:
        return {
            ...state,
            regimeEspecial: payload.regimeEspecial
        };
    case SAVE_COMPANY_LISTA_ATIVIDADES:
        return {
            ...state,
            atividades: payload.atividades
        };
    default:
        return state;
    }
}
