import {
    SAVE_DESTINY_DATA_CNPJ,
    SAVE_DESTINY_DATA_EMAIL,
    SAVE_DESTINY_DATA_TELEFONE,
    SAVE_DESTINY_DATA_RAZAO_SOCIAL,
    SAVE_DESTINY_DATA_NOME_FANTASIA,
    SAVE_DESTINY_DATA_INSC_MUNICIPAL,
} from '../actions/destinyData-actions';

export default function companyDataReducer(state = '', { type, payload }) {
    switch (type) {
    case SAVE_DESTINY_DATA_CNPJ:
        return {
            ...state,
            cnpj: payload.cnpj
        };
    case SAVE_DESTINY_DATA_EMAIL:
        return {
            ...state,
            email: payload.email
        };
    case SAVE_DESTINY_DATA_TELEFONE:
        return {
            ...state,
            telefone: payload.telefone
        };
    case SAVE_DESTINY_DATA_RAZAO_SOCIAL:
        return {
            ...state,
            razaoSocial: payload.razaoSocial
        };
    case SAVE_DESTINY_DATA_NOME_FANTASIA:
        return {
            ...state,
            nomeFantasia: payload.nomeFantasia
        };
    case SAVE_DESTINY_DATA_INSC_MUNICIPAL:
        return {
            ...state,
            inscricaoMunicipal: payload.inscricaoMunicipal
        };
    default:
        return state;
    }
}
