import {
    SAVE_PERSONAL_DATA_CPF,
    SAVE_PERSONAL_DATA_CEP,
    SAVE_PERSONAL_DATA_BAIRRO,
    SAVE_PERSONAL_DATA_CIDADE,
    SAVE_PERSONAL_DATA_ESTADO,
    SAVE_PERSONAL_DATA_NUMERO,
    SAVE_PERSONAL_DATA_ENDERECO,
    SAVE_PERSONAL_DATA_TELEFONE
} from '../actions/personalData-actions';

export default function companyDataReducer(state = '', { type, payload }) {
    switch (type) {
    case SAVE_PERSONAL_DATA_CPF:
        return {
            ...state,
            cpf: payload.cpf
        };
    case SAVE_PERSONAL_DATA_CEP:
        return {
            ...state,
            cep: payload.cep
        };
    case SAVE_PERSONAL_DATA_TELEFONE:
        return {
            ...state,
            telefone: payload.telefone
        };
    case SAVE_PERSONAL_DATA_BAIRRO:
        return {
            ...state,
            bairro: payload.bairro
        };
    case SAVE_PERSONAL_DATA_CIDADE:
        return {
            ...state,
            cidade: payload.cidade
        };
    case SAVE_PERSONAL_DATA_ESTADO:
        return {
            ...state,
            estado: payload.estado
        };
    case SAVE_PERSONAL_DATA_NUMERO:
        return {
            ...state,
            numero: payload.numero
        };
    case SAVE_PERSONAL_DATA_ENDERECO:
        return {
            ...state,
            endereco: payload.endereco
        };
    default:
        return state;
    }
}
