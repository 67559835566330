import {
    SAVE_VALUE,
    SAVE_ALIQUOTA
} from '../actions/receipt-actions';

export default function cnpjReducer(state = '', { type, payload}) {
    switch (type) {
    case SAVE_VALUE:
        return {
            ...state,
            value: payload.value
        };
    case SAVE_ALIQUOTA:
        return {
            ...state,
            aliquota: payload.aliquota
        };
    default:
        return state;
    }
}
