import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchUserData, getApiToken, getUserCompany} from '../../api';
import { addHours, formatISO } from 'date-fns';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import LoaderSpinner from 'react-loader-spinner';

import './styles.css';
import {
    saveCidade,
    saveCompanyCnpj,
    saveEmail, saveEstado, saveInscEstadual,
    saveInscMunicipal, saveNomeFantasia,
    saveRazaoSocial,
    saveTelefone
} from '../../actions/companyData-actions';
import {saveCnpj} from '../../actions/cnpj-actions';
import {saveBairro, saveCep, saveEndereco, saveNumero} from '../../actions/personalData-actions';
import {setInvalidToken} from '../../actions/api-actions';

const mapStateToProps = state => ({

});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveCep: saveCep,
        saveCnpj: saveCnpj,
        saveEmail: saveEmail,
        saveCidade: saveCidade,
        saveEstado: saveEstado,
        saveBairro: saveBairro,
        saveNumero: saveNumero,
        saveEndereco: saveEndereco,
        saveCompanyCnpj: saveCompanyCnpj,
        saveCompanyTelefone: saveTelefone,
        saveRazaoSocial: saveRazaoSocial,
        setInvalidToken: setInvalidToken,
        saveInscMunicial: saveInscMunicipal,
        saveInscEstadual: saveInscEstadual,
        saveNomeFantasia: saveNomeFantasia,
    }, dispatch);
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function LoadingData(props) {

    const query = useQuery();
    const token = query.get('token');

    useEffect(() => {
        getApiToken()
            .then(
                async response => {
                    localStorage.setItem('clientToken', `Bearer ${token}`);
                    localStorage.setItem('apiToken', `Bearer ${response.data.token}`);
                    localStorage.setItem('apiTokenExpiration', formatISO(addHours(new Date(), 1)));
                    localStorage.setItem('clientTokenExpiration', formatISO(addHours(new Date(), 1)));

                    await fetchAndSetUserData();

                    props.setFetchUserData(false);
                }
            )
            .catch(
                error => {
                    Sentry.captureException(error);
                    props.setFeedBackType('error');
                    props.setFeedBackMessage('Ocorreu um erro ao obter seus dados, por favor, ' +
                        'recarregue a página e tente novamente.');
                }
            );
    }, []);

    const fetchAndSetUserData = async () => {
        await getUserCompany()
            .then(
                async response => {
                    await props.saveCep(response.data.cep);
                    await props.saveCnpj(response.data.cnpj);
                    await props.saveCidade(response.data.cidade);
                    await props.saveBairro(response.data.bairro);
                    await props.saveNumero(response.data.numero);
                    await props.saveEstado(response.data.estado);
                    await props.saveCompanyCnpj(response.data.cnpj);
                    await props.saveCompanyCnpj(response.data.cnpj);
                    await props.saveEndereco(response.data.endereco);
                    await props.saveRazaoSocial(response.data.razaosocial);
                    await props.saveCompanyTelefone(response.data.telefone);
                    await props.saveNomeFantasia(response.data.nomefantasia);
                    await props.saveInscEstadual(response.data.inscricaoestadual);
                    await props.saveInscMunicial(response.data.inscricaomunicipal);
                }
            )
            .catch(
                error => {
                    if (error.response.status === 401) {
                        props.setInvalidToken(true);
                    }
                }
            );
    };

    return (
        <div className={'loading-screen-content'}>
            <div className={'loader'}>
                <LoaderSpinner
                    width={150}
                    height={150}
                    color="#e1173f"
                    type="TailSpin"
                    className={'loading-spinner'}
                />
                <h3>
                    Carregando dados...
                </h3>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapActionsToProps)(LoadingData);
