export const SAVE_PERSONAL_DATA_CPF = 'personalData:saveCpf';
export const SAVE_PERSONAL_DATA_CEP = 'personalData:saveCep';
export const SAVE_PERSONAL_DATA_ESTADO = 'personalData:saveEstado';
export const SAVE_PERSONAL_DATA_CIDADE = 'personalData:saveCidade';
export const SAVE_PERSONAL_DATA_BAIRRO = 'personalData:saveBairro';
export const SAVE_PERSONAL_DATA_NUMERO = 'personalData:saveNumero';
export const SAVE_PERSONAL_DATA_TELEFONE = 'personalData:saveTelefone';
export const SAVE_PERSONAL_DATA_ENDERECO = 'personalData:saveEndereco';

export function saveCpf(payload) {
    return {
        type: SAVE_PERSONAL_DATA_CPF,
        payload: {
            cpf: payload
        }
    };
}

export function saveCep(payload) {
    return {
        type: SAVE_PERSONAL_DATA_CEP,
        payload: {
            cep: payload
        }
    };
}

export function saveTelefone(payload) {
    return {
        type: SAVE_PERSONAL_DATA_TELEFONE,
        payload: {
            telefone: payload
        }
    };
}

export function saveEstado(payload) {
    return {
        type: SAVE_PERSONAL_DATA_ESTADO,
        payload: {
            estado: payload
        }
    };
}

export function saveCidade(payload) {
    return {
        type: SAVE_PERSONAL_DATA_CIDADE,
        payload: {
            cidade: payload
        }
    };
}

export function saveBairro(payload) {
    return {
        type: SAVE_PERSONAL_DATA_BAIRRO,
        payload: {
            bairro: payload
        }
    };
}

export function saveNumero(payload) {
    return {
        type: SAVE_PERSONAL_DATA_NUMERO,
        payload: {
            numero: payload
        }
    };
}

export function saveEndereco(payload) {
    return {
        type: SAVE_PERSONAL_DATA_ENDERECO,
        payload: {
            endereco: payload
        }
    };
}

