import './styles.css';
import {connect} from 'react-redux';
import {cnpj} from 'cpf-cnpj-validator';
import {bindActionCreators} from 'redux';
import StatusBar from '../../Status-bar';
import MaskedInput from 'react-input-mask';
import LoaderSpinner from 'react-loader-spinner';
import ProgressButton from 'react-progress-button';
import React, {useEffect, useState} from 'react';
import {
    consultCnpj, consultStatus, fetchCityDetails, fetchCitySmu, fetchLC116List,
    getCities,
    getStates
} from '../../../api';
import {
    saveAtividade,
    saveCidade, saveCnae,
    saveCompanyCnpj, saveCompanyLC116, saveCompanyListaRegimes, saveCompanyRegimeEspecial, saveCompanySmu,
    saveEmail,
    saveEstado, saveInscEstadual, saveInscMunicipal, saveListaAtividades,
    saveNomeFantasia, saveRazaoSocial, saveTelefone
} from '../../../actions/companyData-actions';
import {faChevronLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {saveCredentialType, saveLoginType} from '../../../actions/credential-actions';
import ReactGA from "react-ga";

const compomentStyles = {
    title: {
        height: '50px',
        width: '300px',
        color: '#000000',
        fontSize: '24px',
        marginTop: '26px',
        alignSelf: 'center',
        fontFamily: 'Baloo Bhai, cursive',
    },
    input: {
        width: '20em',
        placeContent: 'center',
        textAlign: '-webkit-center',
    },
    customInput: {
        width: '20em',
        height: '53px',
        padding: '1em',
        margin: '0.5em .5em 0 0',
        borderRadius: '.25rem',
        border: '1px solid #ced4da'
    },
    customSelect: {
        width: '20em',
        height: '53px',
        padding: '.5em',
        borderRadius: '.25rem',
        margin: '0.5em .5em 0 0',
        border: '1px solid #ced4da'
    },
    longerInput: {
        width: '30em'
    },
    centerDoubleInput: {
        textAlign: '-webkit-center'
    },
    emailInput: {
        width: '18em'
    },
    telInput: {
        width: '11em'
    },
    doubleInput: {
        display: 'flex',
        flexDirection: 'row',
        placeContent: 'center',
        textAlign: '-webkit-center'
    },
    nextButton: {
        padding: '1em',
        width: '223px',
        height: '53px',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '50px',
        backgroundColor: '#e1173f',
    },
    cnpjSpinner: {
        width: '45px',
        height: '45px',
        marginTop: '1em',
        marginLeft: '21.3em',
        position: 'absolute',
    },
    cnpjTimes: {
        width: '25px',
        height: '25px',
        marginTop: '1.3em',
        marginLeft: '18.4em',
        position: 'absolute',
    }
};

const mapStateToProps = state => ({
    cnpj: state.companyData.cnpj,
    email: state.companyData.email,
    lc116: state.companyData.lc116,
    cidade: state.companyData.cidade,
    estado: state.companyData.estado,
    regimes: state.companyData.regimes,
    telefone: state.companyData.telefone,
    atividades: state.companyData.atividades,
    razaoSocial: state.companyData.razaoSocial,
    inscEstadual: state.companyData.inscEstadual,
    nomeFantasia: state.companyData.nomeFantasia,
    inscMunicipal: state.companyData.inscMunicipal,
    regimeEspecial: state.companyData.regimeEspecial
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveEmail: saveEmail,
        saveCidade: saveCidade,
        saveEstado: saveEstado,
        saveCnpj: saveCompanyCnpj,
        saveNome: saveNomeFantasia,
        saveRazao: saveRazaoSocial,
        saveTelefone: saveTelefone,
        saveLc116: saveCompanyLC116,
        saveAtividade: saveAtividade,
        saveLoginType: saveLoginType,
        saveInscEstadual: saveInscEstadual,
        saveInscMunicipal: saveInscMunicipal,
        saveCredentialType: saveCredentialType,
        saveListaAtividades: saveListaAtividades,
        saveListaRegimes: saveCompanyListaRegimes,
        saveRegimeEspecial: saveCompanyRegimeEspecial
    }, dispatch);
};

const DadosEmpresa = (props) => {

    const [states, setStates] = useState([]);

    const [cities, setCities] = useState([]);

    const [usaRegime, setUsaRegime] = useState(false);

    const [validCnpj, setValidCnpj] = useState(null);

    const [buttonState, setButtonState] = useState('');

    const [transactionId, setTransactionId] = useState(null);

    const [telMask, setTelMask] = useState('(99) 99999-9999');

    const [listaDescricoes, setListaDescricoes] = useState([]);

    const [loadingActivity, setLoadingActivity] = useState(false);

    const [shouldConsultCnpj, setShouldConsultCnpj] = useState(true);

    const [citiesLabel, setCitiesLabel] = useState('Selecione um estado');

    useEffect(() => {
        if (validCnpj && shouldConsultCnpj) {
            setTimeout(() => {
                setButtonState('loading');
            }, 400);
            consultCnpj(props.cnpj.match(/\d+/g).join('')).then(
                (response) => {
                    setTransactionId(response.data.TransactionID);
                }
            ).catch(
                (err) => {
                    props.handleRequestError(err);
                    setTimeout(() => {
                        setButtonState('error');
                    }, 400);
                }
            );
        } else if (validCnpj !== null && !validCnpj) {
            setTimeout(() => {
                setButtonState('disabled');
            }, 400);
        }
    }, [validCnpj]);

    useEffect(() => {
        if (transactionId) {
            consultStatus(transactionId).then(
                (response) => {
                    switch (response.data.Transaction_Return.cod) {
                    case 99:
                        const dados = response.data.dados.cartaoNacionalPessoaJuridica;
                        populateForm(dados);
                        setTimeout(() => {
                            setButtonState('success');
                        }, 400);
                        return;
                    case 96:
                        setValidCnpj(false);
                        setTimeout(() => {
                            setButtonState('error');
                        }, 400);
                        return;
                    case 1:
                    case 2:
                        setTimeout(() => {
                            setButtonState('loading');
                        }, 400);
                        setTimeout(() => consultInLoop(transactionId), 5000);
                        return;
                    default:
                        //todo - tratar erro aqui
                        console.log(response);
                        return;
                    }
                }
            ).catch(
                (err) => props.handleRequestError(err)
            );
        }
    }, [transactionId]);

    useEffect(() => {
        if (props.inscMunicipal !== '') {
            document.getElementById('munLabel').className = 'input-label written_input';
        } else {
            document.getElementById('munLabel').className = 'input-label empty_input';
        }
    }, [props.inscMunicipal]);

    useEffect(() => {
        if (props.inscEstadual && props.inscEstadual !== '') {
            document.getElementById('estLabel').className = 'input-label written_input';
        } else {
            document.getElementById('estLabel').className = 'input-label empty_input';
        }
    }, [props.inscEstadual]);

    useEffect(() => {
        if (props.cidade && props.cidade !== '') {
            handleCidade(props.cidade);
        }
    }, [cities]);

    useEffect(() => {
        setTimeout(() => {
            setButtonState('loading');
        }, 400);
        if (props.cnpj && (props.inscMunicipal || props.inscEstadual) && props.razaoSocial && props.nomeFantasia &&
        props.email && props.telefone) {
            setShouldConsultCnpj(false);
            validateCnpj(props.cnpj);
            getStates()
                .then(
                    (response) => {
                        if (typeof props.estado === 'string') {
                            response.data.map(item => {
                                if (item.dessigla === props.estado) {
                                    props.saveEstado(item.codigo);
                                    handleEstado(item.codigo);
                                }
                                setStates(response.data);
                            });
                        }
                        else if (typeof props.estado === 'number') {
                            response.data.map(item => {
                                if (item.codigo === props.estado) {
                                    props.saveEstado(item.codigo);
                                    handleEstado(item.codigo);
                                }
                                setStates(response.data);
                            });
                        }
                        else {
                            setStates(response.data);
                            setTimeout(() => {
                                setButtonState('success');
                            }, 400);
                        }
                        setTimeout(() => {
                            setButtonState('success');
                        }, 400);
                    }
                )
                .catch(
                    (err) => {
                        props.handleRequestError(err);
                        setTimeout(() => {
                            setButtonState('error');
                        }, 400);
                    }
                );
            setTimeout(() => {
                setButtonState('success');
            }, 400);
        }
        else {
            if (props.cnpj && props.cnpj !== '' && props.cnpj.match(/\d+/g) &&
                props.cnpj.match(/\d+/g).join('').length > 0) {
                setValidCnpj(null);
                setTimeout(() => {
                    setButtonState('loading');
                }, 400);
                consultCnpj(props.cnpj.match(/\d+/g).join('')).then(
                    (response) => {
                        setValidCnpj(true);
                        setTransactionId(response.data.TransactionID);
                        setTimeout(() => {
                            setButtonState('success');
                        }, 400);
                    }
                ).catch(
                    (err) => {
                        props.handleRequestError(err);
                        setTimeout(() => {
                            setButtonState('error');
                        }, 400);
                    }
                );
            }
            else if (props.cnpj && props.cnpj !== '' && props.cnpj.match(/\d+/g) === null) {
                props.saveCnpj('');
            }
            if ((typeof props.estado !== 'string') && props.estado) {
                handleEstado(props.estado);
            }
            if (props.atividade && props.atividade.length > 0) {
                handleAtividade(props.atividade);
            }
            fillStates();
        }
        if (props.atividades.length > 0) {
            if (props.atividade) {
                handleAtividade(props.atividade.codigo);
            }
            else {
                handleAtividade(props.atividades[0].codigo);
            }
        }
    }, []);

    const fillStates = () => {
        getStates().then(
            (response) => {
                if (typeof props.estado === 'string') {
                    response.data.map(item => {
                        if (item.dessigla === props.estado) {
                            props.saveEstado(item.codigo);
                            handleEstado(item.codigo);
                        }
                        setStates(response.data);
                    });
                } else {
                    setStates(response.data);
                }
                setTimeout(() => {
                    setButtonState('success');
                }, 400);
            }
        ).catch(
            (err) => {
                props.handleRequestError(err);
            }
        );
    };

    const validateCnpj = (value) => {
        if (value !== '' && value.match(/\d+/g).join('').length >= 14) {
            setValidCnpj(cnpj.isValid(value, /\d+/g));
        }
    };

    const populateForm = async (dados) => {
        const cnaeArray = dados.atividadesEconomicasSecundarias;
        cnaeArray.push(dados.atividadeEconomicaPrimaria);
        props.saveTelefone(dados.telefone);
        props.saveNome(dados.nomeFantasia);
        props.saveListaAtividades(cnaeArray);
        handleAtividade(cnaeArray[0].codigo);
        props.saveRazao(dados.nomeEmpresarial);
        props.saveEmail(dados.enderecoEletronico);
        if (dados.endereco.uf.length > 0) {
            await states.map(async (item) => {
                if (item.dessigla === dados.endereco.uf) {
                    props.saveEstado(item.codigo);
                    await handleEstado(item.codigo);
                    cities.map((item) => {
                        if (item.desnome.toLowerCase() === dados.endereco.municipio.toLowerCase()) {
                            props.saveCidade(item.codigo);
                        }
                    });
                }
            });
        }
    };

    const consultInLoop = (transactionId) => {
        consultStatus(transactionId).then(
            (response) => {
                switch (response.data.Transaction_Return.cod) {
                case 99:
                    const dados = response.data.dados.cartaoNacionalPessoaJuridica;
                    populateForm(dados);
                    setTimeout(() => {
                        setButtonState('success');
                    }, 400);
                    return;
                case 96:
                    setValidCnpj(false);
                    setTimeout(() => {
                        setButtonState('error');
                    }, 400);
                    return;
                case 1:
                case 2:
                    setTimeout(() => {
                        setButtonState('loading');
                    }, 400);
                    setTimeout(() => consultInLoop(transactionId), 5000);
                    return;
                default:
                    //todo - tratar erro aqui
                    console.log(response);
                    return;
                }
            }
        ).catch(
            (err) => props.handleRequestError(err)
        );
    };

    // HANDLE CHANGES

    const handleCnpjChange = (value) => {
        props.saveCnpj(value);
    };

    const handleInscMunicipal = (value) => {
        props.saveInscMunicipal(value);
    };

    const handleInscEstadual = (value) => {
        props.saveInscEstadual(value);
    };

    const handleRazao = (value) => {
        props.saveRazao(value);
    };

    const handleNome = (value) => {
        props.saveNome(value);
    };

    const handeEmail = (value) => {
        props.saveEmail(value);
    };

    const handleTelefone = (value) => {
        props.saveTelefone(value);
    };

    const handleEstado = async (value) => {
        if (typeof value !== 'string') {
            props.saveEstado(value);
            setButtonState('loading');
            setCitiesLabel('Carregando...');
            setCities([]);
            await getCities(value).then(
                (response) => {
                    setCities(response.data);
                    if (props.cidade && props.cidade !== '') {
                        if (typeof props.cidade === 'string') {
                            console.log(props.cidade);
                            response.data.map(item => {
                                if (item.desnome === props.cidade) {
                                    handleCidade(item.codigo);
                                }
                            });
                        }
                        handleCidade(props.cidade);
                    }
                    setButtonState('success');
                }
            ).catch(
                (err) => props.handleRequestError(err)
            );
        } else {
            states.map(item => {
                if (item.dessigla === value || item.codigo === parseInt(value)) {
                    handleEstado(parseInt(item.codigo));
                }
            })
        }
    };

    const handleAtividade = async (value) => {
        props.saveAtividade(value);
        setLoadingActivity(true);
        await fetchLC116List(value).then(
            (response) => {
                setListaDescricoes(response.data.lc116);
                handleLC116(response.data.lc116[0].codigo);
                setLoadingActivity(false);
            }
        ).catch(
            (err) => props.handleRequestError(err)
        );
    };

    const handleLC116 = (value) => {
        props.saveLc116(value);
    };

    const handleCidade = (value) => {
        setTimeout(() => {
            setButtonState('loading');
        }, 400);
        if (typeof value === 'number') {
            props.saveCidade(value);
            states.map(estado => {
                if (estado.codigo === parseInt(props.estado)) {
                    cities.map(cidade => {
                        if (cidade.codigo === parseInt(value)) {
                            fetchCityDetails(estado.dessigla, cidade.desnome.toLowerCase()).then(
                                (response) => {
                                    if (typeof response.data.resposta === 'string'
                                        && response.data.resposta.match(/(homologada)/g).length > 0) {
                                        props.saveCidade('');
                                        props.saveLoginType(0);
                                        props.handleStepBack(3);
                                    }
                                    props.saveLoginType(response.data.resposta.tipoAutenticacao);
                                    setUsaRegime(response.data.resposta.usaRegimeEspecialTributacao);
                                    props.saveCredentialType(response.data.resposta.assinaturaDigital);
                                    props.saveListaRegimes(response.data.resposta.regimesEspecialTributacao);
                                    if (response.data.resposta.usaRegimeEspecialTributacao) {
                                        props.saveRegimeEspecial(response.data.resposta.regimesEspecialTributacao[0].codigo);
                                    }
                                    setTimeout(() => {
                                        setButtonState('success');
                                    }, 400);
                                }
                            ).catch(
                                (err) => {
                                    props.handleRequestError(err);
                                    setTimeout(() => {
                                        setButtonState('error');
                                    }, 400);
                                }
                            );
                        }
                    });
                }
            });
        } else if (typeof value === 'string') {
            states.map(estado => {
                if (estado.codigo === parseInt(props.estado)) {
                    cities.map(cidade => {
                        if (cidade.desnome.toString() === value.toString() || cidade.codigo === parseInt(value)) {
                            handleCidade(parseInt(cidade.codigo));
                        }
                    });
                }
            });
        }
    };

    const handleRegime = (value) => {
        props.saveRegimeEspecial(value);
    };

    // Next Stage
    const handleSubmit = () => {
        let missing = 0;
        props.setFeedBackType('');
        props.setFeedBackMessage('');
        if (buttonState === 'loading' || buttonState === 'disabled' || loadingActivity) {
            return;
        } else {
            if (!props.cnpj || props.cnpj === '') {
                props.setFeedBackType('warning');
                props.setFeedBackMessage('Por favor, digite um CNPJ para poder continuar');
                setTimeout(() => {
                    setButtonState('error');
                }, 400);
                return;
            }
            else if (props.cnpj.match(/\d+/g).join('').length < 14 || validCnpj !== true) {
                props.setFeedBackType('warning');
                props.setFeedBackMessage('Parece que o CNPJ digitado e invalido, por favor, insira um cnpj valido para continuar');
                setTimeout(() => {
                    setButtonState('error');
                }, 400);
                return;
            }
            else if (props.inscEstadual === '' && props.inscMunicipal === '') {
                missing++;
            }
            Object.entries(props).map(
                (item) => {
                    if (typeof item[1] === 'string' || typeof item[1] === 'object') {
                        switch (item[0]) {
                        case 'cnpj':
                        case 'inscEstadual':
                        case 'inscMunicipal':
                            break;
                        case 'regimeEspecial':
                            if (usaRegime && item[1] === '') missing++;
                            break;
                        default:
                            if (item[1] === '') missing++;
                            break;
                        }
                    }
                }
            );
            if (missing > 0) {
                props.setFeedBackType('warning');
                props.setFeedBackMessage('Nenhum campo pode estar vazio, por favor, preencha todos os dados para continuar');
                setTimeout(() => {
                    setButtonState('error');
                }, 400);
            } else {
                setTimeout(() => {
                    setButtonState('success');
                }, 400);
                props.setFeedBackType('');
                props.setFeedBackMessage('');
                props.handleNextStep();
            }
        }
    };

    return (
        <div className="content" style={{height: 'auto'}}>
            <StatusBar currentStatus={2}/>

            <div className="header mt-5 mb-4">
                <span className="mb-2" style={compomentStyles.title}>Dados da sua empresa</span>
                <div className="mt-2">
                    <span className="header-sub">Insira as informações para iniciarmos o processo de emissão de nota</span>
                </div>
            </div>


            <div className="input-container form-input-group">
                <div style={{width: '336px', margin: 'auto', display: 'flex', flexDirection: 'row'}}>
                    {(validCnpj === false) && (
                        <FontAwesomeIcon
                            icon={faTimes}
                            style={compomentStyles.cnpjTimes}
                        />
                    )}
                    <div className={'label-content'}>
                        <MaskedInput
                            required
                            id={'cnpj'}
                            type="text"
                            value={props.cnpj}
                            mask='99.999.999/9999-99'
                            style={validCnpj ? {
                                ...compomentStyles.customInput,
                                border: '1px solid #28a745'
                            } : (validCnpj !== null) ? {
                                ...compomentStyles.customInput,
                                border: '1px solid #dc3545'
                            } : {...compomentStyles.customInput}}
                            onBlur={(e) => validateCnpj(e.target.value)}
                            onChange={(e) => handleCnpjChange(e.target.value)}
                        />
                        <label
                            htmlFor={'cnpj'}
                            className={'input-label'}
                        >
                                CNPJ
                        </label>
                    </div>
                </div>
                {(validCnpj !== null && !validCnpj && props.cnpj !== '') && (
                    <span
                        style={{
                            width: '30em',
                            color: 'red',
                            alignSelf: 'center',
                            transition: 'display 0.4ms'
                        }}>
                        O CNPJ Digitado e invalido, por favor, verifique-o para continuar</span>
                )}
            </div>
            <div className="input-container form-input-group" style={compomentStyles.doubleInput}>
                <div className={'label-content'}>
                    <input
                        id={'inscmun'}
                        type="text"
                        style={{
                            ...compomentStyles.customInput,
                            width: '12em',
                            marginRight: '1em'
                        }}
                        className="form-control"
                        value={props.inscMunicipal}
                        onChange={(e) => handleInscMunicipal(e.target.value)}
                    />
                    <label
                        id={'munLabel'}
                        htmlFor={'inscmun'}
                        className={'input-label'}
                    >
                            Inscriçao Municipal
                    </label>
                </div>
                <div className={'label-content'}>
                    <input
                        type="text"
                        id={'insces'}
                        style={{
                            ...compomentStyles.customInput,
                            width: '12em'
                        }}
                        value={props.inscEstadual}
                        onChange={(e) => handleInscEstadual(e.target.value)}
                        className="form-control"
                    />
                    <label
                        id={'estLabel'}
                        htmlFor={'insces'}
                        className={'input-label'}
                    >
                            Inscriçao Estadual
                    </label>
                </div>
            </div>
            <div className="input-container form-input-group" style={{textAlign: '-webkit-center'}}>
                <div className={'raz-social-content'}>
                    <div className={'label-content'}>
                        <input
                            required
                            id={'razaoSocial'}
                            style={{
                                ...compomentStyles.customInput,
                                ...compomentStyles.longerInput
                            }}
                            type="text"
                            value={props.razaoSocial}
                            className="form-control"
                            onChange={(e) => handleRazao(e.target.value)}
                        />
                        <label
                            htmlFor={'razaoSocial'}
                            className={'input-label'}
                        >
                            Razao Social
                        </label>
                    </div>
                </div>
            </div>
            <div className="input-container form-input-group" style={{textAlign: '-webkit-center'}}>
                <div className={'raz-social-content'}>
                    <div className={'label-content'}>
                        <input
                            required
                            id={'nomeFanstasia'}
                            style={{
                                ...compomentStyles.customInput,
                                ...compomentStyles.longerInput
                            }}
                            type="text"
                            value={props.nomeFantasia}
                            onChange={(e) => handleNome(e.target.value)}
                            className="form-control"
                        />
                        <label
                            htmlFor={'nomeFanstasia'}
                            className={'input-label'}
                        >
                                Nome Fantasia
                        </label>
                    </div>
                </div>
            </div>
            <div className="input-container double-input-align" style={compomentStyles.centerDoubleInput}>
                <div className={'label-content'}>
                    <select
                        id={'activity'}
                        style={{
                            ...compomentStyles.customSelect,
                            width: '14.5em'
                        }}
                        value={props.atividade}
                        disabled={!(props.atividades.length > 0)}
                        className="form-control custom-input"
                        onChange={(e) => handleAtividade(e.target.value)}
                    >
                        {
                            (props.atividades.length > 0 ? (
                                props.atividades.map((item, key) => (
                                    <option value={item.codigo} key={key}>{item.descricao}</option>
                                ))
                            ) : (
                                <option value={null}>
                                    {(props.cnpj && props.cnpj.length > 0 && validCnpj)
                                        ? 'Carregando...' : 'Selecione a atividade'}
                                </option>
                            ))
                        }
                    </select>
                    <label
                        htmlFor={'activity'}
                        className={'input-label written_input'}
                    >
                            Atividade
                    </label>
                </div>
                <div className={'label-content'}>
                    <select
                        id={'desc'}
                        style={{
                            width: '14.5em'
                        }}
                        value={props.lc116}
                        disabled={listaDescricoes.length === 0}
                        className="form-control custom-input-2"
                        onChange={(e) => handleLC116(e.target.value)}
                    >
                        {
                            (listaDescricoes.length > 0 ? (
                                listaDescricoes.map((item, key) => (
                                    <option
                                        key={key}
                                        value={item.codigo}>
                                        {item.descricao}
                                    </option>
                                ))
                            ) : (
                                <option value={null}>
                                    {(props.cnpj && props.cnpj.length > 0 && validCnpj) ?
                                        'Carregando...' : 'Selecione o serviço'}
                                </option>
                            ))
                        }
                    </select>
                    <label
                        htmlFor={'desc'}
                        className={'input-label written_input'}
                    >
                            Descrição da atividade
                    </label>
                </div>
            </div>
            <div className="input-container double-input-align" style={compomentStyles.centerDoubleInput}>
                <div className={'label-content'}>
                    <input
                        required
                        style={{
                            ...compomentStyles.customInput,
                            ...compomentStyles.emailInput
                        }}
                        id="email"
                        type="email"
                        value={props.email}
                        className="form-control custom-input"
                        onChange={(e) => handeEmail(e.target.value)}
                    />
                    <label
                        htmlFor={'email'}
                        className={'input-label written_input'}
                    >
                            E-mail
                    </label>
                </div>
                <div className={'label-content'}>
                    <MaskedInput
                        style={{
                            ...compomentStyles.customInput,
                            ...compomentStyles.telInput
                        }}
                        required
                        id="tel"
                        type="tel"
                        mask={telMask}
                        value={props.telefone}
                        onChange={(e) => handleTelefone(e.target.value)}
                        className="form-control custom-input input-tel-size"
                    />
                    <label
                        htmlFor={'tel'}
                        className={'input-label written_input'}
                    >
                            Telefone
                    </label>
                </div>
            </div>
            <div className="input-container double-input-align" style={compomentStyles.centerDoubleInput}>
                <div className={'label-content'}>
                    <select
                        id={'state'}
                        name={'state'}
                        style={{
                            ...compomentStyles.customSelect,
                            ...compomentStyles.telInput
                        }}
                        value={props.estado}
                        className="form-control custom-input"
                        onChange={(e) => handleEstado(e.target.value)}
                    >
                        {
                            (states.length > 0 ? (
                                states.map((item, key) => (
                                    <option value={item.codigo} key={key}>{item.dessigla}</option>
                                ))
                            ) : (
                                <option value={null}>Carregando...</option>
                            ))
                        }
                    </select>
                    <label
                        htmlFor={'state'}
                        className={'input-label written_input'}
                    >
                            Estado
                    </label>
                </div>
                <div className={'label-content'}>
                    <select
                        style={{
                            ...compomentStyles.emailInput
                        }}
                        id={'city'}
                        name={'city'}
                        value={props.cidade}
                        disabled={cities.length === 0}
                        className="form-control custom-input-2"
                        onChange={(e) => handleCidade(e.target.value)}
                    >
                        {
                            (cities.length > 0 ? (
                                cities.map((item, key) => (
                                    <option
                                        key={key}
                                        value={item.codigo}>
                                        {item.desnome}
                                    </option>
                                ))
                            ) : (
                                <option value={null}>{citiesLabel}</option>
                            ))
                        }
                    </select>
                    <label
                        htmlFor={'city'}
                        className={'input-label written_input'}
                    >
                            Cidade
                    </label>
                </div>
            </div>
            {
                (usaRegime) && (
                    <div className="row">
                        <div className="col-md-3"/>
                        <div className="col-md-6 form-input-group" style={{textAlign: '-webkit-center'}}>
                            <select
                                style={{
                                    ...compomentStyles.customInput,
                                    ...compomentStyles.longerInput
                                }}
                                value={props.regimeEspecial}
                                placeholder="Razao Social"
                                className="form-control"
                                onChange={(e) => handleRegime(e.target.value)}
                            >
                                {
                                    (props.regimes && props.regimes.length > 0 ? (
                                        props.regimes.map((item, key) =>
                                            <option value={item.codigo} key={key}>{item.nome}</option>)
                                    ) : (
                                        <option value={null}>Carregando...</option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-md-3"/>
                    </div>
                )
            }

            <div className="history-footer-buttons">
                <div
                    className="attachment-back-button"
                    onClick={() => props.handleStepBack()}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className="attachment-back-icon"/>
                    <span>Voltar</span>
                </div>
                <ProgressButton
                    state={buttonState}
                    onClick={handleSubmit}
                >
                    Iniciar agora
                </ProgressButton>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(DadosEmpresa);
