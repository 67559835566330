export const SAVE_VALUE = 'receipt:saveValue';
export const SAVE_ALIQUOTA = 'receipt:saveAliquota';

export function saveValue(value) {
    return {
        type: SAVE_VALUE,
        payload: {
            value: value
        }
    };
}

export function saveAliquota(value) {
    return {
        type: SAVE_ALIQUOTA,
        payload: {
            aliquota: value
        }
    };
}
