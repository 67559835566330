import storage from 'redux-persist/lib/storage';
import {combineReducers, createStore} from 'redux';
import expireReducer from 'redux-persist-expire';
import { persistStore, persistReducer } from 'redux-persist';

import apiReducer from '../reducers/apiReducer';

import credentialReducer from '../reducers/credentialReducer';
import destinyDataReducer from '../reducers/destinyDataReducer';
import serviceDataReducer from '../reducers/serviceDataReducer';
import companyDataReducer from '../reducers/companyDataReducer';
import personalDataReducer from '../reducers/personalDataReducer';
import receiptReducer from '../reducers/receiptReducer';
import receiptsReducer from '../reducers/receiptsReducer';
import purchaseReducer from '../reducers/purchaseReducer';

const initialState = {
    apiData: {
        invalidToken: false
    },
    companyData: {
        smu: '',
        cnpj: '',
        cnae: '',
        email: '',
        lc116: '',
        estado: '',
        cidade: '',
        regimes: [],
        telefone: '',
        atividade: '',
        atividades: [],
        razaoSocial: '',
        inscEstadual: '',
        nomeFantasia: '',
        inscMunicipal: '',
        regimeEspecial: '',
    },
    personalData: {
        cpf: '',
        cep: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        telefone: '',
        endereco: '',
    },
    credentials: {
        login: '',
        token: '',
        password: '',
        loginType: null,
        attachmentData: '',
        credentialType: null
    },
    destinyData: {
        cnpj: '',
        email: '',
        telefone: '',
        razaoSocial: '',
        nomeFantasia: '',
        inscricaoMunicipal: ''
    },
    serviceData: {
        servico: '',
        atividade: '',
        descricao: ''
    },
    receiptData: {
        value: '',
        aliquota: ''
    },
    receipts: {
        receipts: [],
        selectedReceipt: null
    },
    purchase: {
        plan: '',
        method: 0,
        link: '',
    }
};

const persistConfig = {
    storage,
    key: 'root',
    transforms: [
        expireReducer('companyData', {
            autoExpire: true,
            expireSeconds: 3600,
            expiredState: initialState.companyData
        }),
        expireReducer('personalData', {
            autoExpire: true,
            expireSeconds: 3600,
            expiredState: initialState.personalData
        }),
        expireReducer('destinyData', {
            autoExpire: true,
            expireSeconds: 3600,
            expiredState: initialState.destinyData
        }),
        expireReducer('serviceData', {
            autoExpire: true,
            expireSeconds: 3600,
            expiredState: initialState.serviceData
        }),
        expireReducer('receiptData', {
            autoExpire: true,
            expireSeconds: 3600,
            expiredState: initialState.receiptData
        }),
    ],
    blacklist: ['apiData', 'credentials']
};

const allReducers = combineReducers({
    apiData: apiReducer,
    receipts: receiptsReducer,
    purchase: purchaseReducer,
    receiptData: receiptReducer,
    credentials: credentialReducer,
    destinyData: destinyDataReducer,
    serviceData: serviceDataReducer,
    companyData: companyDataReducer,
    personalData: personalDataReducer,
});

const persistedReducer = persistReducer(persistConfig, allReducers);

const store = createStore(
    persistedReducer,
    {...initialState},
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

const persistor = persistStore(store);

export { store, persistor };
