import './styles.css';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import StatusBar from '../../Status-bar';
import {bindActionCreators} from 'redux';
import ProgressButton from 'react-progress-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {saveAliquota, saveValue} from '../../../actions/receipt-actions';
import {getUserCompany, registerClientMei, registerTomador, sendReceipt} from '../../../api';

const mapStateToProps = state => ({
    companyData: state.companyData,
    credentials: state.credentials,
    serviceData: state.serviceData,
    value: state.receiptData.value,
    destinyData: state.destinyData,
    personalData: state.personalData,
    aliquota: state.receiptData.aliquota,
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveValue: saveValue,
        saveAliquota: saveAliquota
    }, dispatch);
};

const Valor = (props) => {

    const [buttonState, setButtonState] = useState('');

    const [registeredCompany, setRegisteredCompany] = useState(null);

    useEffect(() => {
        getUserCompany()
            .then(() => {
                setRegisteredCompany(true);
            })
            .catch(() => {
                setRegisteredCompany(false);
            });
    }, []);

    const handleValue = (value) => {
        props.saveValue(value);
    };

    const handleAliquota = (value) => {
        props.saveAliquota(value);
    };

    const sendNota = async (data) => {
        await sendReceipt(data).then(
            (response) => {
                setTimeout(() => {
                    setButtonState('error');
                }, 400);
                props.handleStepBack(8);
            }
        ).catch(
            (error) => {
                if (error.response.data.Transaction_Return) {
                    if (error.response.data.Transaction_Return.transaction_codigo === 12) {
                        props.setFeedBackType('error');
                        props.setFeedBackMessage('Você não possui saldo para essa emissão. Caso tenha feito o ' +
                            'seu pedido por boleto, o pagamento pode demorar até 2 dias úteis para ser concluído.');
                    }
                } else {
                    props.handleRequestError(error);
                }
                setTimeout(() => {
                    setButtonState('error');
                }, 400);
            }
        );
    };

    const handleSubmit = async () => {
        setTimeout(() => {
            setButtonState('loading');
        }, 400);
        if (props.value === '' || props.value === 0) {
            props.setFeedBackType('warning');
            props.setFeedBackMessage('O valor da nota não pode ser 0 ou vazio');
            setTimeout(() => {
                setButtonState('error');
            }, 400);
        }
        else if (props.value === '') {
            props.setFeedBackType('warning');
            props.setFeedBackMessage('O valor da aliquota não pode ser vazio');
            setTimeout(() => {
                setButtonState('error');
            }, 400);
        } else {
            const toSendCompanyData = {
                'nomeFantasia': props.companyData.nomeFantasia,
                'nomeEmpresarial': props.companyData.razaoSocial,
                'cnpj': props.companyData.cnpj.match(/\d+/g).join(''),
                'email': props.companyData.email,
                'telefone': props.companyData.telefone,
                'inscricaoestadual': props.companyData.inscEstadual,
                'inscricaomunicipal': props.companyData.inscMunicipal,
                'senha': props.credentials.password,
                'tokenacesso': props.credentials.token,
                'codigo_servico_municipio': props.serviceData.servico,
                'item_lista_servicolc116': props.serviceData.atividade,
                'cep': props.personalData.cep,
                'uf': props.personalData.estado,
                'cidade': props.personalData.cidade,
                'bairro': props.personalData.bairro,
                'endereco': props.personalData.endereco,
                'complemento': '',
                'numero': props.personalData.numero,
                'pontoreferencia': ''
            };
            const toSendTomadorData = {
                cnpj: props.destinyData.cnpj,
                email: props.destinyData.email,
                telefone: props.destinyData.telefone,
                inscricaoMunicipal: props.destinyData.inscricaoMunicipal
            };
            const toSendReceipt = {
                CNPJPrestador: props.companyData.cnpj,
                Valor: props.value,
                CodServico: props.serviceData.servico,
                CNPJTomador: props.destinyData.cnpj,
                ServicoDescricao: props.serviceData.descricao,
                AliquotaISS: props.aliquota,
                Cnae: props.companyData.cnae,
                Lc116: props.companyData.lc116,
                Atividade: props.companyData.atividade,
                Origem: 'Bradesco'
            };
            if (registeredCompany === false) {
                await registerClientMei(toSendCompanyData)
                    .catch(
                        (err) => {
                            props.handleRequestError(err);
                            setTimeout(() => {
                                setButtonState('error');
                            }, 400);
                        }
                    );
            }
            await registerTomador(toSendTomadorData).then(
                async () => {
                    sendNota(toSendReceipt);
                }
            ).catch(
                (err) => {
                    if (err.response.status === 400) {
                        sendNota(toSendReceipt);
                    } else {
                        setTimeout(() => {
                            setButtonState('error');
                        }, 400);
                        props.handleRequestError(err);
                    }
                }
            );
        }
    };

    return (
        <div className="content" style={{height: '57.99em'}}>
            <StatusBar currentStatus={8} />

            <span className="head-title-value">
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="head-icon-value"
                    onClick={() => props.handleStepBack()}
                />
                    Valor da nota
            </span>
            <span className="header-sub-value mb-3">Informe o valor do serviço prestado</span>

            <div className="row">
                <div className="col-md-4"/>
                <div className="col-md-4 double-input-align-value">
                    <input
                        type="number"
                        value={props.value}
                        placeholder="Valor da Nota"
                        className="form-control custom-input-value spacing-bottom"
                        onChange={(e) => handleValue(e.target.value)}
                    />
                    <input
                        type="number"
                        value={props.aliquota}
                        placeholder="Aliquota"
                        className="form-control custom-input-value spacing-bottom"
                        onChange={(e) => handleAliquota(e.target.value)}
                    />
                </div>
                <div className="col-md-4"/>
            </div>

            <div className="footer-buttons-value">
                <div className="back-button-value"
                    onClick={() => props.handleStepBack()}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className="back-icon-value"/>
                    <span className="back-text">Voltar</span>
                </div>
                <ProgressButton
                    state={buttonState}
                    onClick={() => handleSubmit()}
                >
                        Emitir Nota
                </ProgressButton>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Valor);
