export const SET_RECEIPTS = 'receipts:setReceipts';
export const SET_SELECTED_RECEIPT = 'receipts:setSelectedReceipt';

export function setReceipts(payload) {
    return {
        type: SET_RECEIPTS,
        payload: payload
    };
}

export function setSelectedReceipt(payload) {
    return {
        type: SET_SELECTED_RECEIPT,
        payload: payload
    };
}
