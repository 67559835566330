import React from 'react';
import Security from '../Header/Security';
import iconArrowTop from './icon-arrow-top-white.svg';

import './styles.css';

export default function Footer() {

    function scrollToTop() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <footer className="footer">
            <div className="footer__container">
                <Security color="white" />
                <div className="footer__copyright">
                    <p className="paragraph paragraph_small paragraph_white bold">Todos os direitos reservados a DicasMEI</p>
                    <p className="paragraph paragraph_small paragraph_white bold">CNPJ: 29.162.060/0001-92</p>
                </div>
                <button className="footer__btn-scroll" onClick={scrollToTop}>
                    <div className="footer__btn-scroll-icon">
                        <img src={iconArrowTop} alt="Ícone de uma seta apontando para cima" />
                    </div>
                </button>
            </div>
        </footer>
    );
}
