import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFrown
} from '@fortawesome/free-regular-svg-icons';

const componentStyles = {
    motherDiv: {
        width: '40em',
        height: '31.1em',
        display: 'flex',
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'row',
    },
    sadFaceDiv: {
        height: '6em',
        width: '12em',
        marginTop: '4.8em',
        marginRight: '1em',
    },
    sadTextDiv: {
        height: '6em',
        marginTop: '4em',
        textAlign: 'left'
    },
    sadFaceIcon: {

    },
    text: {
        fontSize: '1.3em',
        fontWeight: '600',
        textAlign: 'left',
        color: '#252525',
    }
};

const Invalid = () => {
    return (
        <div
            style={componentStyles.motherDiv}
        >
            <div style={componentStyles.sadFaceDiv}>
                <FontAwesomeIcon
                    fixedWidth
                    size={'4x'}
                    icon={faFrown}
                    color={'#ff3f4e'}
                    style={componentStyles.sadFaceIcon}
                />
            </div>
            <div style={componentStyles.sadTextDiv}>
                <span style={componentStyles.text}>
                    {'Ops! Parece que sua sessão expirou, mas não se preocupe, basta recarregar a página para voltar a usar nosso sistema novamente.'}
                </span>
            </div>
        </div>
    );
};

export default Invalid;
