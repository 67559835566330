import React from 'react';
import {
    faCheck
} from '@fortawesome/free-solid-svg-icons';
import history from '../../utils/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';

class StatusBar extends React.Component {

    componentDidMount() {
        this.setState({
            doneStyle: {
                backgroundColor: '#1eb99a'
            }
        });
    }

    render() {
        const { currentStatus } = this.props;
        const done = this.state ? this.state.doneStyle : {};

        return (
            <div className="status-bar">
                <div className="circles">
                    <div className="mx-custom circle-align">
                        <div className="circle" style={currentStatus >= 2 ? done : {}}/>
                        <FontAwesomeIcon
                            style={{
                                display: currentStatus >= 2 ? 'inline-block' : 'none'
                            }}
                            icon={faCheck}
                            className="fas fa-check status-check"
                        />
                        <span className="status-text-2">Dados da Empresa</span>
                    </div>
                    <div className="mx-custom circle-align">
                        <div className="circle" style={currentStatus >= 3 ? done : {}}/>
                        <FontAwesomeIcon
                            style={{
                                display: currentStatus >= 3 ? 'inline-block' : 'none'
                            }}
                            icon={faCheck}
                            className="fas fa-check status-check"
                        />
                        <span className="status-text-2">Dados pessoais</span>
                    </div>
                    <div className="mx-custom circle-align">
                        <div className="circle" style={currentStatus >= 4 ? done : {}}/>
                        <FontAwesomeIcon
                            style={{
                                display: currentStatus >= 4 ? 'inline-block' : 'none'
                            }}
                            icon={faCheck}
                            className="fas fa-check status-check"
                        />
                        <span className="status-text-2">Pagamento</span>
                    </div>
                    <div className="mx-custom circle-align">
                        <div className="circle" style={currentStatus >= 5 ? done : {}}/>
                        <FontAwesomeIcon
                            style={{
                                display: currentStatus >= 5 ? 'inline-block' : 'none'
                            }}
                            icon={faCheck}
                            className="fas fa-check status-check"
                        />
                        <span className="status-text-2">Credenciais de acesso</span>
                    </div>
                    <div className="mx-custom circle-align">
                        <div className="circle" style={currentStatus >= 6 ? done : {}}/>
                        <FontAwesomeIcon
                            style={{
                                display: currentStatus >= 6 ? 'inline-block' : 'none'
                            }}
                            icon={faCheck}
                            className="fas fa-check status-check"
                        />
                        <span className="status-text-2">Para quem é a nota?</span>
                    </div>
                    <div className="mx-custom circle-align">
                        <div className="circle" style={currentStatus >= 7 ? done : {}}/>
                        <FontAwesomeIcon
                            style={{
                                display: currentStatus >= 7 ? 'inline-block' : 'none'
                            }}
                            icon={faCheck}
                            className="fas fa-check status-check"
                        />
                        <span className="status-text-2">Sobre o serviço</span>
                    </div>
                    <div className="mx-custom circle-align">
                        <div className="circle" style={currentStatus >= 8 ? done : {}}/>
                        <FontAwesomeIcon
                            style={{
                                display: currentStatus >= 8 ? 'inline-block' : 'none'
                            }}
                            icon={faCheck}
                            className="fas fa-check status-check"
                        />
                        <span className="status-text-2">Valor da nota</span>
                    </div>
                </div>
                <div className="line"/>
            </div>
        );
    }
}

export default StatusBar;
