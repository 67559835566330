import {
    SET_RECEIPTS,
    SET_SELECTED_RECEIPT
} from '../actions/receipts-actions';

export default function cnpjReducer(state = '', { type, payload}) {
    switch (type) {
    case SET_RECEIPTS:
        return {
            ...state,
            receipts: payload
        };
    case SET_SELECTED_RECEIPT:
        return {
            ...state,
            selectedReceipt: payload
        };
    default:
        return state;
    }
}
