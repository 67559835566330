export const SET_PLAN = 'purchase:setPlan';
export const SET_METHOD = 'purchase:setMethod';
export const SET_LINK = 'purchase:setLink';

export function setMethod(payload) {
    return {
        type: SET_METHOD,
        payload: payload
    };
}

export function setPurchaseOption(payload) {
    return {
        type: SET_PLAN,
        payload: payload
    };
}

export function setLink(payload) {
    return {
        type: SET_LINK,
        payload: payload
    };
}
