export const SAVE_SERVICE_DATA_ATIVIDADE = 'serviceData:saveAtividade';
export const SAVE_SERVICE_DATA_SERVICO = 'serviceData:saveServico';
export const SAVE_SERVICE_DATA_DESCRICAO = 'serviceData:saveDescricao';

export function saveAtividade(payload) {
    return {
        type: SAVE_SERVICE_DATA_ATIVIDADE,
        payload: {
            atividade: payload
        }
    };
}

export function saveServico(payload) {
    return {
        type: SAVE_SERVICE_DATA_SERVICO,
        payload: {
            servico: payload
        }
    };
}

export function saveDescricao(payload) {
    return {
        type: SAVE_SERVICE_DATA_DESCRICAO,
        payload: {
            descricao: payload
        }
    };
}
