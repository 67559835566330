export const SAVE_COMPANY_CNPJ = 'companyData:saveCnpj';
export const SAVE_COMPANY_CNAE = 'companyData:saveCnae';
export const SAVE_COMPANY_EMAIL = 'companyData:saveEmail';
export const SAVE_COMPANY_ESTADO = 'companyData:saveEstado';
export const SAVE_COMPANY_CIDADE = 'companyData:saveCidade';
export const SAVE_COMPANY_SMU = 'companyData:saveCompanySmu';
export const SAVE_COMPANY_TELEFONE = 'companyData:saveTelefone';
export const SAVE_COMPANY_LC116 = 'companyData:saveCompanyLC116';
export const SAVE_COMPANY_ATIVIDADE = 'companyData:saveAtividade';
export const SAVE_COMPANY_RAZAO_SOCIAl = 'companyData:saveRazaoSocial';
export const SAVE_COMPANY_INSC_ESTADUAL = 'companyData:saveInscEstadual';
export const SAVE_COMPANY_NOME_FANTASIA = 'companyData:saveNomeFantasia';
export const SAVE_COMPANY_INSC_MUNICIPAL = 'companyData:saveInscMunicipal';
export const SAVE_COMPANY_LISTA_ATIVIDADES = 'companyData:saveListaAtividades';
export const SAVE_COMPANY_LISTA_REGIMES = 'companyData:saveCompanyListaRegimes';
export const SAVE_COMPANY_REGIME_ESPECIAL = 'companyData:saveCompanyRegimeEspecial';

export function saveCnae(payload) {
    return {
        type: SAVE_COMPANY_CNAE,
        payload: {
            cnae: payload
        }
    };
}

export function saveCompanySmu(payload) {
    return {
        type: SAVE_COMPANY_SMU,
        payload: {
            smu: payload
        }
    };
}

export function saveCompanyCnpj(payload) {
    return {
        type: SAVE_COMPANY_CNPJ,
        payload: {
            cnpj: payload
        }
    };
}

export function saveEmail(payload) {
    return {
        type: SAVE_COMPANY_EMAIL,
        payload: {
            email: payload
        }
    };
}

export function saveEstado(payload) {
    return {
        type: SAVE_COMPANY_ESTADO,
        payload: {
            estado: payload
        }
    };
}

export function saveCidade(payload) {
    return {
        type: SAVE_COMPANY_CIDADE,
        payload: {
            cidade: payload
        }
    };
}

export function saveTelefone(payload) {
    return {
        type: SAVE_COMPANY_TELEFONE,
        payload: {
            telefone: payload
        }
    };
}

export function saveRazaoSocial(payload) {
    return {
        type: SAVE_COMPANY_RAZAO_SOCIAl,
        payload: {
            razaoSocial: payload
        }
    };
}

export function saveInscEstadual(payload) {
    return {
        type: SAVE_COMPANY_INSC_ESTADUAL,
        payload: {
            inscEstadual: payload
        }
    };
}

export function saveNomeFantasia(payload) {
    return {
        type: SAVE_COMPANY_NOME_FANTASIA,
        payload: {
            nomeFantasia: payload
        }
    };
}

export function saveInscMunicipal(payload) {
    return {
        type: SAVE_COMPANY_INSC_MUNICIPAL,
        payload: {
            inscMunicipal: payload
        }
    };
}

export function saveCompanyLC116(payload) {
    return {
        type: SAVE_COMPANY_LC116,
        payload: {
            lc116: payload
        }
    };
}

export function saveCompanyRegimeEspecial(payload) {
    return {
        type: SAVE_COMPANY_REGIME_ESPECIAL,
        payload: {
            regimeEspecial: payload
        }
    };
}

export function saveListaAtividades(payload) {
    return {
        type: SAVE_COMPANY_LISTA_ATIVIDADES,
        payload: {
            atividades: payload
        }
    };
}

export function saveAtividade(payload) {
    return {
        type: SAVE_COMPANY_ATIVIDADE,
        payload: {
            atividade: payload
        }
    };
}

export function saveCompanyListaRegimes(payload) {
    return {
        type: SAVE_COMPANY_LISTA_REGIMES,
        payload: {
            regimes: payload
        }
    };
}
