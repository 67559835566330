export const SAVE_CNPJ = 'cnpj:saveCnpj';

export function saveCnpj(cnpj) {
    return {
        type: SAVE_CNPJ,
        payload: {
            cnpj: cnpj
        }
    };
}
