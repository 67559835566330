export const SAVE_LOGIN_TYPE = 'credential:saveLoginType';
export const SAVE_CREDENTIAL_TYPE = 'credential:saveCredentialType';
export const SAVE_CREDENTIAL_LOGIN = 'credential:saveCredentialLogin';
export const SAVE_CREDENTIAL_TOKEN = 'credential:saveCredentialToken';
export const SAVE_CREDENTIAL_PASS = 'credential:saveCredentialFilePassword';
export const SAVE_ATTACHMENT_DATA = 'credential:saveCredentialAttachmentData';

export function saveLoginType(value) {
    return {
        type: SAVE_LOGIN_TYPE,
        payload: {
            loginType: value
        }
    };
}

export function saveCredentialType(value) {
    return {
        type: SAVE_CREDENTIAL_TYPE,
        payload: {
            credentialType: value
        }
    };
}

export function saveCredentialLogin(value) {
    return {
        type: SAVE_CREDENTIAL_LOGIN,
        payload: {
            login: value
        }
    };
}

export function saveCredentialAttachmentData(value) {
    return {
        type: SAVE_ATTACHMENT_DATA,
        payload: {
            attachmentData: value
        }
    };
}

export function saveCredentialPassword(value) {
    return {
        type: SAVE_CREDENTIAL_PASS,
        payload: {
            password: value
        }
    };
}

export function saveCredentialToken(value) {
    return {
        type: SAVE_CREDENTIAL_TOKEN,
        payload: {
            token: value
        }
    };
}
