import {
    SAVE_SERVICE_DATA_SERVICO,
    SAVE_SERVICE_DATA_DESCRICAO,
    SAVE_SERVICE_DATA_ATIVIDADE,
} from '../actions/serviceData-actions';

export default function companyDataReducer(state = '', { type, payload }) {
    switch (type) {
    case SAVE_SERVICE_DATA_SERVICO:
        return {
            ...state,
            servico: payload.servico
        };
    case SAVE_SERVICE_DATA_DESCRICAO:
        return {
            ...state,
            descricao: payload.descricao
        };
    case SAVE_SERVICE_DATA_ATIVIDADE:
        return {
            ...state,
            atividade: payload.atividade
        };
    default:
        return state;
    }
}
