export const SAVE_DESTINY_DATA_CNPJ = 'destinyData:saveCnpj';
export const SAVE_DESTINY_DATA_EMAIL = 'destinyData:saveEmail';
export const SAVE_DESTINY_DATA_TELEFONE = 'destinyData:saveTelefone';
export const SAVE_DESTINY_DATA_RAZAO_SOCIAL = 'destinyData:saveRazaoSocial';
export const SAVE_DESTINY_DATA_NOME_FANTASIA = 'destinyData:saveNomeFantasia';
export const SAVE_DESTINY_DATA_INSC_MUNICIPAL = 'destinyData:saveInscricaoMunicipal';

export function saveCnpj(payload) {
    return {
        type: SAVE_DESTINY_DATA_CNPJ,
        payload: {
            cnpj: payload
        }
    };
}

export function saveRazaoSocial(payload) {
    return {
        type: SAVE_DESTINY_DATA_RAZAO_SOCIAL,
        payload: {
            razaoSocial: payload
        }
    };
}

export function saveTelefone(payload) {
    return {
        type: SAVE_DESTINY_DATA_TELEFONE,
        payload: {
            telefone: payload
        }
    };
}

export function saveNomeFantasia(payload) {
    return {
        type: SAVE_DESTINY_DATA_NOME_FANTASIA,
        payload: {
            nomeFantasia: payload
        }
    };
}

export function saveEmail(payload) {
    return {
        type: SAVE_DESTINY_DATA_EMAIL,
        payload: {
            email: payload
        }
    };
}

export function saveInscricaoMunicipal(payload) {
    return {
        type: SAVE_DESTINY_DATA_INSC_MUNICIPAL,
        payload: {
            inscricaoMunicipal: payload
        }
    };
}

