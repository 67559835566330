import React from 'react';
import Axios from 'axios';
import {store} from '../store';
import {
    Redirect
} from 'react-router-dom';
import {
    SET_INVALID_TOKEN
} from '../actions/api-actions';
import {addHours, formatISO} from 'date-fns';

const api = Axios.create({
    baseURL: 'https://www.dicasmei.com.br/api/'
    // baseURL: 'https://dev.dicasmei.com.br/api/' // DEV
});

const dispatchInvalidAction = (status) => {
    return store.dispatch({
        type: SET_INVALID_TOKEN,
        payload: status
    });
};

// TOKEN TYPES 1 - API // 2 - CLIENT
const validateAndGetToken = async (type) => {
    const token = type === 1 ?
        localStorage.getItem('apiToken') : localStorage.getItem('clientToken');
    const expiration = type === 1 ?
        localStorage.getItem('apiTokenExpiration') : localStorage.getItem('clientTokenExpiration');

    if (type === 1) {
        if (!token || token === '' || new Date() > new Date(expiration)) {
            return await getApiToken().then(
                (response) => {
                    localStorage.setItem('apiToken', `Bearer ${response.data.token}`);
                    localStorage.setItem('apiTokenExpiration', formatISO(addHours(new Date(), 1)));
                    return `Bearer ${response.data.token}`;
                }
            );
        }
        else {
            return token;
        }
    }
    else if (type === 2) {
        if (token && token.includes('null') || !token || token === '' || new Date() > new Date(expiration)) {
            dispatchInvalidAction(true);
        }
        else {
            return token;
        }
    }
    return null;
};

export const getApiToken = () => {
    return api.post('login_check', {
        'username': 'api_user',
        'password': 'pZ25ShU2DRo3gxYf'
    }, {
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
};

export const getStates = async () => {
    return api.get('endereco/estado', {
        headers : {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: await validateAndGetToken(1)
        }
    });
};

export const getCities = async (stateCod) => {
    return api.get(`endereco/estado/${stateCod}/cidades`, {
        headers : {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: await validateAndGetToken(1)
        }
    });
};

export const getCepInformation = (cep) => {
    return Axios.get(`https://viacep.com.br/ws/${cep}/json`, {
        headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    });
};

export const sendCredentialFile = async () => {
    const state = store.getState();
    const cnpj = state.companyData.cnpj.match(/\d+/g).join('');
    const password = state.credentials.password;
    const file = state.credentials.attachmentData;
    let bodyFormData = new FormData();
    bodyFormData.append('arquivo', file);
    bodyFormData.append('senha', password);
    return api.post(`cliente/notasinternas/empresa/vincular/certificado?cnpj=${cnpj}`,
        bodyFormData,
        {
            headers : {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                Authorization: await validateAndGetToken(2).then(
                    (response) => response
                )
            }
        });
};

export const consultCnpj = async (cnpj) => {
    return api.post('cnpj/solicita', {
        CNPJ: cnpj,
        tp_consulta: 'X'
    }, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: await validateAndGetToken(1)
        }
    });
};

export const consultStatus = async (transaction) => {
    return api.post('cnpj/consulta', {
        TransactionID: transaction
    }, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(1)
        }
    });
};

export const fetchLC116List = async (cnae) => {
    return api.get(`lc116/listar/${cnae}`, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(1)
        }
    });
};

export const fetchCityDetails = async (uf, municipio) => {
    return api.get(`cliente/notasinternas/prefeitura/detalhes?uf=${uf}&cidade=${municipio}`, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(2)
        }
    });
};

export const fetchCitySmu = async (ibge) => {
    return api.get('notasinternas/servico/smu?ibge='+ibge, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(1)
        }
    });
};

export const fetchUserData = async () => {
    return api.get('cnpj/solicita', {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: await validateAndGetToken(2)
        }
    });
};

export const getUserCompany = async () => {
    return api.get('cliente/mei', {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: await validateAndGetToken(2)
        }
    });
};

export const sendPaymentData = async (data) => {
    return api.post('cliente/dadospagamento',
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: await validateAndGetToken(2)
            }
        });
};

export const registerPayment = async (data, serviceId, quantidade) => {
    return api.post(`cliente/pagamento/unico/${serviceId}?quantidade=${quantidade}&origem=BRADESCO`,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: await validateAndGetToken(2)
            }
        });
};

// Receipts
export const getReceipts = async () => {
    return api.get('cliente/notas', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(2)
        }
    });
};

// CREATE OR UPDATE COMPANY CALLS
export const registerClientMei = async (data) => {
    return api.post('cliente/mei/cadastrar',
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: await validateAndGetToken(2)
            }
        });
};

// REGISTER COMPANY TOMADOR
export const registerTomador = async (data) => {
    return api.post('cliente/tomador/cadastrar', data, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(2)
        },
    });
};

// REGISTER RECEIPT
export const sendReceipt = async (data) => {
    return api.post('cliente/nota/envio', data, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(2)
        },
    });
};

export const getReceiptStatus = async (transactionID) => {
    return api.post('cliente/nota/consulta', {
        TransactionID: transactionID
    }, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(2)
        },
    });
};

export const getUserCreditTokens = async () => {
    return api.get('cliente/tokens', {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(2)
        },
    });
};

export const consumeCreditToken = async () => {
    return api.patch('cliente/tokens', {
        servico: 15
    }, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: await validateAndGetToken(2)
        },
    });
}


