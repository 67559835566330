export const SET_INVALID_TOKEN = 'api:setInvalidToken';

export function setInvalidToken(payload) {
    return {
        type: SET_INVALID_TOKEN,
        payload: {
            invalidToken: payload
        }
    };
}
