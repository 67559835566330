import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import App from './App';
import {setInvalidToken} from './actions/api-actions';

const mapStateToProps = state => ({
    invalidToken: state.apiData.invalidToken
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        setInvalidToken: setInvalidToken
    }, dispatch);
};

export default connect(mapStateToProps, mapActionsToProps)(App);
