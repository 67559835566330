import './styles.css';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import StatusBar from '../../Status-bar';
import {bindActionCreators} from 'redux';
import {faChevronLeft, faTimes} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    saveCnpj,
    saveEmail, saveInscricaoMunicipal,
    saveNomeFantasia,
    saveRazaoSocial,
    saveTelefone
} from '../../../actions/destinyData-actions';
import {
    consultCnpj,
    consultStatus
} from '../../../api';
import {cnpj} from 'cpf-cnpj-validator';
import MaskedInput from 'react-input-mask';
import LoaderSpinner from 'react-loader-spinner';
import ProgressButton from 'react-progress-button';

const mapStateToProps = state => ({
    cnpj: state.destinyData.cnpj,
    email: state.destinyData.email,
    telefone: state.destinyData.telefone,
    razaoSocial: state.destinyData.razaoSocial,
    nomeFantasia: state.destinyData.nomeFantasia,
    inscricaoMunicipal: state.destinyData.inscricaoMunicipal
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveCnpj: saveCnpj,
        saveEmail: saveEmail,
        saveTelefone: saveTelefone,
        saveRazaoSocial: saveRazaoSocial,
        saveNomeFantasia: saveNomeFantasia,
        saveInscricaoMunicipal: saveInscricaoMunicipal
    }, dispatch);
};

const componentStyles = {
    input: {
        width: '20em',
        placeContent: 'center',
        textAlign: '-webkit-center',
    },
    customInput: {
        width: '20em',
        height: '50px',
        padding: '1em',
        margin: '.5em auto',
        borderRadius: '.25rem',
        border: '1px solid #ced4da'
    },
    longerInput: {
        width: '30em'
    },
    centerDoubleInput: {
        justifyContent: 'center',
        textAlign: '-webkit-center'
    },
    emailInput: {
        width: '18em'
    },
    telInput: {
        width: '11em'
    },
    doubleInput: {
        display: 'flex',
        flexDirection: 'row',
        placeContent: 'center',
        textAlign: '-webkit-center'
    },
    backButtons: {
        color: '#00000',
    },
    nextButton: {
        width: '223px',
        height: '58px',
        padding: '1em',
        cursor: 'pointer',
        marginLeft: '1.5em',
        textAlign: 'center',
        borderRadius: '50px',
        backgroundColor: '#e1173f'
    }
};

const DestinoNota = (props) => {

    const [buttonState, setButtonState] = useState('');

    const [validCnpj, setValidCnpj] = useState(null);

    const [transactionId, setTransactionId] = useState(null);

    const [telMask, setTelMask] = useState('(99) 99999-9999');

    useEffect(() => {
        if (validCnpj) {
            setTimeout(() => {
                setButtonState('loading');
            }, 400);
            consultCnpj(props.cnpj.match(/\d+/g).join('')).then(
                (response) => {
                    setTransactionId(response.data.TransactionID);
                }
            ).catch(
                (err) => {
                    props.handleRequestError(err);
                    setTimeout(() => {
                        setButtonState('error');
                    }, 400);
                }
            );
        }
    }, [validCnpj]);

    useEffect(() => {
        if (transactionId) {
            consultStatus(transactionId).then(
                (response) => {
                    switch (response.data.Transaction_Return.cod) {
                    case 99:
                        const dados = response.data.dados.cartaoNacionalPessoaJuridica;
                        populateForm(dados);
                        setTimeout(() => {
                            setButtonState('success');
                        }, 400);
                        return;
                    case 96:
                        setValidCnpj(false);
                        setTimeout(() => {
                            setButtonState('error');
                        }, 400);
                        return;
                    case 1:
                    case 2:
                        setTimeout(() => consultInLoop(transactionId), 5000);
                        return;
                    default:
                        //todo - tratar erro aqui
                        console.log(response);
                        return;
                    }
                }
            ).catch(
                (err) => console.log(err)
            );
        }
    }, [transactionId]);

    useEffect(() => {
        if (props.cnpj && props.cnpj.match(/\d+/g).join('').length > 0) {
            consultCnpj(props.cnpj.match(/\d+/g).join('')).then(
                (response) => {
                    setValidCnpj(true);
                    setTransactionId(response.data.TransactionID);
                }
            ).catch(
                (err) => console.log(err)
            );
        }
    }, []);

    const validateCnpj = (value) => {
        if (value !== '') {
            setValidCnpj(cnpj.isValid(value, /\d+/g));
        }
    };

    const populateForm = async (dados) => {
        props.saveTelefone(dados.telefone);
        props.saveEmail(dados.enderecoEletronico);
        props.saveNomeFantasia(dados.nomeFantasia);
        props.saveRazaoSocial(dados.nomeEmpresarial);
    };

    const consultInLoop = (transactionId) => {
        consultStatus(transactionId).then(
            (response) => {
                switch (response.data.Transaction_Return.cod) {
                case 99:
                    const dados = response.data.dados.cartaoNacionalPessoaJuridica;
                    populateForm(dados);
                    setTimeout(() => {
                        setButtonState('success');
                    }, 400);
                    return;
                case 96:
                    setValidCnpj(false);
                    setTimeout(() => {
                        setButtonState('error');
                    }, 400);
                    return;
                case 1:
                case 2:
                    setTimeout(() => consultInLoop(transactionId), 5000);
                    return;
                default:
                    //todo - tratar erro aqui
                    console.log(response);
                    return;
                }
            }
        ).catch(
            (err) => console.log(err)
        );
    };

    // HANDLE CHANGES

    const handleCnpjChange = (value) => {
        props.saveCnpj(value);
    };

    const handleInscMunicipal = (value) => {
        props.saveInscricaoMunicipal(value);
    };

    const handleRazao = (value) => {
        props.saveRazaoSocial(value);
    };

    const handleNome = (value) => {
        props.saveNomeFantasia(value);
    };

    const handeEmail = (value) => {
        props.saveEmail(value);
    };

    const handleTelefone = (value) => {
        props.saveTelefone(value);
    };

    // Next Stage

    const handleSubmit = () => {
        let missing = 0;
        props.setFeedBackType('');
        props.setFeedBackMessage('');
        if (buttonState === 'loading' || buttonState === 'disabled') {
            return;
        } else {
            if (!props.cnpj || props.cnpj === '') {
                props.setFeedBackType('warning');
                props.setFeedBackMessage('Por favor, digite um CNPJ para poder continuar');
                return;
            }
            else if (validCnpj !== true) {
                props.setFeedBackType('warning');
                props.setFeedBackMessage('Parece que o CNPJ digitado e invalido, por favor, insira um cnpj valido para continuar');
                return;
            }
            else if (props.inscricaoMunicipal === '') {
                missing++;
            }
            Object.entries(props).map(
                (item) => {
                    if (typeof item[1] === 'string') {
                        switch (item[0]) {
                        case 'cnpj':
                        case 'inscricaoMunicipal':
                            break;
                        default:
                            if (item[1] === '') missing++;
                            break;
                        }
                    }
                }
            );
            if (missing > 0) {
                setTimeout(() => {
                    setButtonState('error');
                }, 400);
                props.setFeedBackType('warning');
                props.setFeedBackMessage('Nenhum campo pode estar vazio, por favor, preencha todos os dados para continuar');
            } else {
                setTimeout(() => {
                    setButtonState('success');
                }, 400);
                props.setFeedBackType('');
                props.setFeedBackMessage('');
                props.handleNextStep();
            }
        }
    };

    return (
        <div className={'content'} style={{height: '60em'}}>
            <StatusBar currentStatus={6} />

            <span className="head-title-destination">
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="head-icon-destination"
                    onClick={() => props.handleStepBack()}
                />
                    Para quem é a nota?
            </span>
            <span className="header-sub-destination mb-4">Insira as informações de quem receberá a nota</span>

            <div className="row">
                <div className="col-md-4"/>
                <div
                    className="col-md-4 form-input-group"
                    style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}
                >
                    <div className={'label-content'} style={{width: '350px'}}>
                        <MaskedInput
                            required
                            id={'cnpj'}
                            type="text"
                            value={props.cnpj}
                            mask='99.999.999/9999-99'
                            style={validCnpj ? {
                                ...componentStyles.customInput,
                                border: '1px solid #28a745'
                            } : (validCnpj !== null) ? {
                                ...componentStyles.customInput,
                                border: '1px solid #dc3545'
                            } : {...componentStyles.customInput}}
                            onBlur={(e) => validateCnpj(e.target.value)}
                            onChange={(e) => handleCnpjChange(e.target.value)}
                        />
                        <label
                            htmlFor={'cnpj'}
                            className={'input-label'}
                        >
                            CNPJ
                        </label>
                    </div>
                    {(validCnpj === false) && (
                        <FontAwesomeIcon
                            icon={faTimes}
                            style={componentStyles.cnpjTimes}
                        />
                    )}
                    {(!validCnpj && props.cnpj !== '') && (
                        <span
                            style={{
                                width: '30em',
                                color: 'red',
                                alignSelf: 'center'
                            }}>
                        O CNPJ Digitado e invalido, por favor, verifique-o para continuar</span>
                    )}
                </div>
                <div className="col-md-4"/>
            </div>
            <div className="row">
                <div className="col-md-3"/>
                <div
                    className="col-md-6 form-input-group"
                    style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}
                >
                    <div className={'label-content'} style={{width: '400px'}}>
                        <input
                            required
                            id={'mun'}
                            type="text"
                            style={{
                                ...componentStyles.customInput,
                                width: '25em'
                            }}
                            className="form-control"
                            value={props.inscricaoMunicipal}
                            onChange={(e) => handleInscMunicipal(e.target.value)}
                        />
                        <label
                            htmlFor={'mun'}
                            className={'input-label'}
                        >
                            Inscrição Municipal
                        </label>
                    </div>
                </div>
                <div className="col-md-3"/>
            </div>
            <div className="row">
                <div className="col-md-3"/>
                <div className="col-md-6 form-input-group" style={{textAlign: '-webkit-center'}}>
                    <div className={'label-content'} style={{width: '480px'}}>
                        <input
                            required
                            id={'raz'}
                            style={{
                                ...componentStyles.customInput,
                                ...componentStyles.longerInput
                            }}
                            type="text"
                            value={props.razaoSocial}
                            className="form-control"
                            onChange={(e) => handleRazao(e.target.value)}
                        />
                        <label
                            htmlFor={'raz'}
                            className={'input-label'}
                        >
                            Razao Social
                        </label>
                    </div>
                </div>
                <div className="col-md-3"/>
            </div>
            <div className="row">
                <div className="col-md-3"/>
                <div className="col-md-6 form-input-group" style={{textAlign: '-webkit-center'}}>
                    <div className={'label-content'} style={{width: '480px'}}>
                        <input
                            required
                            id={'fan'}
                            style={{
                                ...componentStyles.customInput,
                                ...componentStyles.longerInput
                            }}
                            type="text"
                            value={props.nomeFantasia}
                            onChange={(e) => handleNome(e.target.value)}
                            className="form-control"
                        />
                        <label
                            htmlFor={'fan'}
                            className={'input-label'}
                        >
                            Nome Fantasia
                        </label>
                    </div>
                </div>
                <div className="col-md-3"/>
            </div>
            <div className='row'>
                <div className={'col-md-3'}/>
                <div className="col-md-6 double-input-align" style={componentStyles.centerDoubleInput}>
                    <div className={'label-content'}>
                        <input
                            required
                            style={{
                                ...componentStyles.customInput,
                                ...componentStyles.emailInput
                            }}
                            id="email"
                            type="email"
                            value={props.email}
                            className="form-control custom-input"
                            onChange={(e) => handeEmail(e.target.value)}
                        />
                        <label
                            htmlFor={'email'}
                            className={'input-label'}
                        >
                            E-mail
                        </label>
                    </div>
                    <div className={'label-content'}>
                        <MaskedInput
                            required
                            style={{
                                ...componentStyles.customInput,
                                ...componentStyles.telInput
                            }}
                            id="tel"
                            type="tel"
                            mask={telMask}
                            value={props.telefone}
                            onChange={(e) => handleTelefone(e.target.value)}
                            className="form-control custom-input input-tel-size"
                        />
                        <label
                            htmlFor={'tel'}
                            className={'input-label'}
                        >
                            Telefone
                        </label>
                    </div>
                </div>
                <div className={'col-md-3'}/>
            </div>

            <div className={'row'}>
                <div className="col-md-4"/>
                <div className="col-md-4 footer-buttons-destination">
                    <div
                        style={{cursor: 'pointer'}}
                        className={'back-button-destination'}
                        onClick={() => props.handleStepBack()}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="back-icon-destination"/>
                        <span className="back-text-destination">Voltar</span>
                    </div>
                    <ProgressButton
                        state={buttonState}
                        onClick={() => handleSubmit()}
                    >
                        <span className="custom-button-destination">Proximo passo</span>
                    </ProgressButton>
                </div>
                <div className="col-md-4"/>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(DestinoNota);
