import { SAVE_LOGIN_TYPE } from '../actions/credential-actions';
import { SAVE_CREDENTIAL_TYPE } from '../actions/credential-actions';
import { SAVE_ATTACHMENT_DATA } from '../actions/credential-actions';
import { SAVE_CREDENTIAL_PASS } from '../actions/credential-actions';
import { SAVE_CREDENTIAL_TOKEN } from '../actions/credential-actions';
import { SAVE_CREDENTIAL_LOGIN } from '../actions/credential-actions';

export default function historyReducer(state = '', { type, payload }) {
    switch (type) {
    case SAVE_LOGIN_TYPE:
        return {
            ...state,
            ...payload
        };
    case SAVE_CREDENTIAL_TYPE:
        return {
            ...state,
            ...payload
        };
    case SAVE_CREDENTIAL_LOGIN:
        return {
            ...state,
            ...payload
        };
    case SAVE_ATTACHMENT_DATA:
        return {
            ...state,
            ...payload
        };
    case SAVE_CREDENTIAL_PASS:
        return {
            ...state,
            ...payload
        };
    case SAVE_CREDENTIAL_TOKEN:
        return {
            ...state,
            ...payload
        };
    default:
        return state;
    }
}
