import './styles.css';
import {
    getCepInformation, getUserCreditTokens, registerPayment, sendPaymentData
} from '../../../api';
import {connect} from 'react-redux';
import {cpf} from 'cpf-cnpj-validator';
import {bindActionCreators} from 'redux';
import StatusBar from '../../Status-bar';
import MaskedInput from 'react-input-mask';
import React, {useEffect, useState} from 'react';
import {
    saveBairro,
    saveCep,
    saveCidade,
    saveCpf,
    saveEndereco,
    saveEstado, saveNumero,
    saveTelefone
} from '../../../actions/personalData-actions';
import {setLink, setMethod} from '../../../actions/purchase-actions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import ProgressButton from 'react-progress-button';
import { find } from 'lodash';
const compomentStyles = {
    title: {
        fontSize: '24px',
        width: '300px',
        height: '50px',
        marginTop: '26px',
        color: '#000000',
        fontFamily: 'Baloo Bhai, cursive',
    },
    input: {
        width: '20em',
        placeContent: 'center',
        textAlign: '-webkit-center',
    },
    customInput: {
        width: '20em',
        height: '50px',
        padding: '1em',
        margin: '.5em 0',
    },
    longerInput: {
        width: '30em'
    },
    centerDoubleInput: {
        justifyContent: 'center',
        textAlign: '-webkit-center'
    },
    emailInput: {
        width: '18em'
    },
    telInput: {
        width: '11em'
    },
    doubleInput: {
        display: 'flex',
        flexDirection: 'row',
        placeContent: 'center',
        textAlign: '-webkit-center'
    },
    nextButton: {
        width: '223px',
        height: '58px',
        padding: '1em',
        cursor: 'pointer',
        textAlign: 'center',
        borderRadius: '50px',
        backgroundColor: '#e1173f'
    }
};

const mapStateToProps = state => ({
    cpf: state.personalData.cpf,
    cep: state.personalData.cep,
    cidade: state.personalData.cidade,
    numero: state.personalData.numero,
    bairro: state.personalData.bairro,
    estado: state.personalData.estado,
    endereco: state.personalData.endereco,
    telefone: state.personalData.telefone,
    plan: state.purchase.plan
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        saveCpf: saveCpf,
        saveCep: saveCep,
        setLink: setLink,
        saveCidade: saveCidade,
        saveEstado: saveEstado,
        saveBairro: saveBairro,
        saveNumero: saveNumero,
        saveTelefone: saveTelefone,
        saveEndereco: saveEndereco,
        setPaymentMethod: setMethod,
    }, dispatch);
};

const DadosPessoa = (props) => {

    const [nome, setNome] = useState('');

    const [cepData, setCepData] = useState(false);

    const [validCpf, setValidCpf] = useState(null);

    const [telMask, setTelMask] = useState('(99) 99999-9999');

    const [buttonState, setButtonState] = useState('');

    useEffect(() => {
        if (props.cep.length >= 8) {
            setTimeout(() => {
                setButtonState('loading');
            }, 400);
            getCepData(props.cep);
        }
    },[props.cep]);

    useEffect(() => {
        if (props.cpf && props.cpf !== '') {
            validateCpf(props.cpf);
        }
    }, []);

    const validateCpf = (value) => {
        if (value !== '') {
            setValidCpf(cpf.isValid(value, /\d+/g));
        }
    };

    const getCepData = (cep) => {
        getCepInformation(cep)
            .then((response) => {
                setCepData(true);
                props.saveEstado(response.data.uf);
                props.saveBairro(response.data.bairro);
                props.saveCidade(response.data.localidade);
                props.saveEndereco(response.data.logradouro);
                setTimeout(() => {
                    setButtonState('success');
                }, 400);
            }).catch((error) => {
                props.handleRequestError(error);
                setCepData(false);
                setTimeout(() => {
                    setButtonState('error');
                }, 400);
            });
    };

    const openCheckout = () => {
        new window.PagarMeCheckout.Checkout({
            encryption_key: 'ek_live_Sn25Xudzdvbp6HPFwCOftHQMwoTD7d', // LIVE
            // encryption_key: 'ek_test_32NmzUnMn4IrQtH7FaDJEgqcvAnDTa', // TEST
            success: function(data) {
                data.installments = '1';
                const quantidade = props.plan === 1 ? 5 : 30;
                const serviceId = props.plan === 1 ? 15 : 13;
                registerPayment(data, serviceId, quantidade)
                    .then(response => {
                        setTimeout(() => {
                            setButtonState('success');
                        }, 400);
                        props.setLink(data.payment_method === 'boleto' ? response.link_boleto : null);
                        props.setPaymentMethod(data.payment_method === 'boleto' ? 1 : 0);
                        props.setFeedBackType('');
                        props.setFeedBackMessage('');
                        props.handleNextStep();
                    })
                    .catch(error => {
                        props.handleRequestError(error);
                        setTimeout(() => {
                            setButtonState('error');
                        }, 400);
                    });
                props.setLink(data.payment_method === 'boleto' ? '' : null);
                props.setPaymentMethod(data.payment_method === 'boleto' ? 1 : 0);
                props.setFeedBackType('');
                props.setFeedBackMessage('');
                props.handleNextStep();
            },
            error: function(err) {
                setTimeout(() => {
                    setButtonState('error');
                }, 400);
                props.setFeedBackType('error');
                props.setFeedBackMessage('Ocorreu um erro ao processar seu pagamento, por favor, ' +
                    'verifique os dados e tente novamente.');
            },
            close: function() {
                props.setFeedBackType('warning');
                props.setFeedBackMessage('É necessário fazer o pagamento para prosseguir.');
            }
        }).open({
            maxInstallments: 1,
            amount: props.plan === 1 ? 1500 : 3000,
            buttonText: 'Pagar',
            customerData: 'false',
            createToken: 'false',
            paymentMethods: 'credit_card, boleto'
        });
    };

    // HANDLE CHANGES

    const handleCpfChange = (value) => {
        props.saveCpf(value);
    };

    const handleCepChange = (value) => {
        props.saveCep(value);
    };

    const handleTelefoneChange = (value) => {
        props.saveTelefone(value);
    };

    const handleEstadoChange = (value) => {
        props.saveEstado(value);
    };

    const handleCidadeChange = (value) => {
        props.saveCidade(value);
    };

    const handleEnderecoChange = (value) => {
        props.saveEndereco(value);
    };

    const handleBairroChange = (value) => {
        props.saveBairro(value);
    };

    const handleNumeroChange = (value) => {
        props.saveNumero(value);
    };

    const handleSubmit = () => {
        if (buttonState === 'loading' || buttonState === 'disabled') return;
        let missing = 0;
        if (props.cpf && props.cpf === '') {
            props.setFeedBackType('warning');
            props.setFeedBackMessage('Por favor, digite um CPF para poder continuar');
            return;
        }
        else if (!nome || nome === '') {
            missing++;
        }
        else if (!validCpf) {
            props.setFeedBackType('warning');
            props.setFeedBackMessage('Parece que o CPF digitado e inválido, por favor, insira um CPF valido para continuar');
            return;
        }
        Object.entries(props).map(
            (item) => {
                if (typeof item[1] === 'string') {
                    if (item[0] !== 'cpf') {
                        if (item[1] === '') missing++;
                    }
                }
            }
        );
        if (missing > 0) {
            setTimeout(() => {
                setButtonState('error');
            }, 400);
            props.setFeedBackType('warning');
            props.setFeedBackMessage('Nenhum campo pode estar vazio, por favor, preencha todos os dados para continuar');
        } else {
            let hasNfeService = false
            getUserCreditTokens()
                .then(response => {
                    if (response.data.length >= 1) {
                        response.data.map(service => {
                            if (service.servico.codservico === 15) {
                                hasNfeService = true
                            }
                        })
                    }
                })
                .catch(e => console.log(e))
            if (hasNfeService) {
                props.setFeedBackType('');
                props.setFeedBackMessage('');
                props.handleNextStep();
            } else {
                setTimeout(() => {
                    setButtonState('loading');
                }, 400);
                sendPaymentData({
                    cpf: props.cpf.match(/\d+/g).join(''),
                    celular: props.telefone,
                    cep: props.cep,
                    nome: nome,
                    estado: props.estado,
                    cidade: props.cidade,
                    bairro: props.bairro,
                    endereco: props.endereco,
                    numero: props.numero
                })
                    .then(() => {
                        openCheckout();
                    })
                    .catch(
                        error => {
                            props.handleRequestError(error);
                        }
                    );
            }
        }
    };

    return (
        <div className="content" style={{height: 'auto', marginBottom: '60px'}}>
            <StatusBar
                currentStatus={3}
                handleStepBack={props.handleStepBack}
            />

            <div className="header mt-3">
                <span style={compomentStyles.title}>Dados pessoais</span>
            </div>

            <div className="row">
                <div className="col-md-4"/>
                <div className="col-md-4 form-input-group"
                    style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                    <div className={'label-content'} style={{width: '320px'}}>
                        <input
                            required
                            id={'name'}
                            type="text"
                            value={nome}
                            style={compomentStyles.customInput}
                            onChange={(e) => setNome(e.target.value)}
                        />
                        <label
                            htmlFor={'name'}
                            className={'input-label'}
                        >
                            Nome
                        </label>
                    </div>
                </div>
                <div className="col-md-4"/>
            </div>
            <div className="row">
                <div className="col-md-4"/>
                <div className="col-md-4 form-input-group"
                    style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                    <div className={'label-content'} style={{width: '320px'}}>
                        <MaskedInput
                            required
                            id={'cpf'}
                            type="text"
                            value={props.cpf}
                            mask='999.999.999-99'
                            style={validCpf ? {
                                ...compomentStyles.customInput,
                                border: '1px solid #28a745'
                            } : (validCpf !== null) ? {
                                ...compomentStyles.customInput,
                                border: '1px solid #dc3545'
                            } : {...compomentStyles.customInput}}
                            onBlur={(e) => validateCpf(e.target.value)}
                            onChange={(e) => handleCpfChange(e.target.value)}
                        />
                        <label
                            htmlFor={'cpf'}
                            className={'input-label'}
                        >
                            CPF
                        </label>
                    </div>
                    {(!validCpf && validCpf !== null) && (
                        <span
                            style={{
                                width: '30em',
                                color: 'red',
                                alignSelf: 'center'
                            }}>
                        O CPF Digitado e invalido, por favor, verifique-o para continuar</span>
                    )}
                </div>
                <div className="col-md-4"/>
            </div>
            <div className="row">
                <div className="col-md-4"/>
                <div className="col-md-4 form-input-group"
                    style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                    <div className={'label-content'} style={{width: '320px'}}>
                        <MaskedInput
                            required
                            id={'tel'}
                            type="tel"
                            mask={telMask}
                            value={props.telefone}
                            style={compomentStyles.customInput}
                            onChange={(e) => handleTelefoneChange(e.target.value)}
                        />
                        <label
                            htmlFor={'tel'}
                            className={'input-label'}
                        >
                            Celular
                        </label>
                    </div>
                </div>
                <div className="col-md-4"/>
            </div>
            <div className="row">
                <div className="col-md-4"/>
                <div className="col-md-4 form-input-group"
                    style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                    <div className={'label-content'} style={{width: '320px'}}>
                        <input
                            required
                            id={'cep'}
                            type="text"
                            placeholder="CEP"
                            value={props.cep}
                            style={compomentStyles.customInput}
                            onChange={(e) => handleCepChange(e.target.value)}
                        />
                        <label
                            htmlFor={'cep'}
                            className={'input-label'}
                        >
                            CEP
                        </label>
                    </div>
                </div>
                <div className="col-md-4"/>
            </div>
            {
                (cepData) &&
                (<div>
                    <div className="row">
                        <div className="col-md-4"/>
                        <div className="col-md-4 form-input-group"
                            style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                            <div className={'label-content'} style={{width: '320px'}}>
                                <input
                                    required
                                    id={'estado'}
                                    type="text"
                                    value={props.estado}
                                    style={compomentStyles.customInput}
                                    onChange={(e) => handleEstadoChange(e.target.value)}
                                />
                                <label
                                    htmlFor={'estado'}
                                    className={'input-label'}
                                >
                                    Estado
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                    <div className="row">
                        <div className="col-md-4"/>
                        <div className="col-md-4 form-input-group"
                            style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                            <div className={'label-content'} style={{width: '320px'}}>
                                <input
                                    required
                                    id={'cidade'}
                                    type="text"
                                    value={props.cidade}
                                    style={compomentStyles.customInput}
                                    onChange={(e) => handleCidadeChange(e.target.value)}
                                />
                                <label
                                    htmlFor={'cidade'}
                                    className={'input-label'}
                                >
                                    Cidade
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                    <div className="row">
                        <div className="col-md-4"/>
                        <div className="col-md-4 form-input-group"
                            style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                            <div className={'label-content'} style={{width: '320px'}}>
                                <input
                                    required
                                    id={'endereco'}
                                    type="text"
                                    value={props.endereco}
                                    style={compomentStyles.customInput}
                                    onChange={(e) => handleEnderecoChange(e.target.value)}
                                />
                                <label
                                    htmlFor={'endereco'}
                                    className={'input-label'}
                                >
                                    Endereço
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                    <div className="row">
                        <div className="col-md-4"/>
                        <div className="col-md-4 form-input-group"
                            style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                            <div className={'label-content'} style={{width: '320px'}}>
                                <input
                                    required
                                    id={'bairro'}
                                    type="text"
                                    value={props.bairro}
                                    style={compomentStyles.customInput}
                                    onChange={(e) => handleBairroChange(e.target.value)}
                                />
                                <label
                                    htmlFor={'bairro'}
                                    className={'input-label'}
                                >
                                    Bairro
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                    <div className="row">
                        <div className="col-md-4"/>
                        <div className="col-md-4 form-input-group"
                            style={{display: 'flex', flexDirection: 'column' ,textAlign: '-webkit-center'}}>
                            <div className={'label-content'} style={{width: '320px'}}>
                                <input
                                    required
                                    id={'numero'}
                                    type="text"
                                    value={props.numero}
                                    style={compomentStyles.customInput}
                                    onChange={(e) => handleNumeroChange(e.target.value)}
                                />
                                <label
                                    htmlFor={'numero'}
                                    className={'input-label'}
                                >
                                    Número
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4"/>
                    </div>
                </div>)
            }

            <div className="history-footer-buttons">
                <div
                    className="attachment-back-button"
                    onClick={() => props.handleStepBack()}
                >
                    <FontAwesomeIcon icon={faChevronLeft} className="attachment-back-icon"/>
                    <span>Voltar</span>
                </div>
                <ProgressButton
                    state={buttonState}
                    onClick={handleSubmit}
                >
                    <span className="history-custom-buttom">
                        Próximo Passo
                    </span>
                </ProgressButton>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(DadosPessoa);
