import React from 'react';

const componentStyles = {
    body: {
        width: '100%',
        color: '#fff',
        padding: '1.6rem 0',
        textAlign: 'center',
    },
    message: {
        margin: 0,
        fontWeight: 'bold',
        fontSize: '1rem',
        textAlign: 'center',
    },
    success: {
        backgroundColor: '#45b347'
    },
    warning: {
        backgroundColor: '#f4c313'
    },
    error: {
        backgroundColor: '#ff8d8d'
    },
    default: {

    }
};

const UserFeedback = ({ message, type }) => {

    if (type && type !== '' && message && message !== '') {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    const typeStyle = (type === 'error') ?
        componentStyles.error : (type === 'warning') ?
            componentStyles.warning : componentStyles.success;

    return (
        (type && type !== '') &&
        (message && message !== '') &&
        <div
            style={{
                ...componentStyles.body,
                ...typeStyle
            }}
        >
            <p style={componentStyles.message}>
                {message}
            </p>
        </div>
    );
};

export default UserFeedback;
