import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
    faCheck,
    faTimes,
    faPlusCircle,
    faMinusCircle,
    faChevronLeft,
    faQuestionCircle
} from '@fortawesome/free-solid-svg-icons';
import {
    faCircle,
    faSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './minhas-notas.css';
import {setReceipts, setSelectedReceipt} from '../../actions/receipts-actions';
import {getReceipts} from '../../api';
import {addHours, formatISO} from 'date-fns';
import {useLocation} from 'react-router-dom';
import LoaderSpinner from 'react-loader-spinner';

const mapStateToProps = state => ({
    receipts: state.receipts.receipts,
    selectedReceipt: state.receipts.selectedReceipt
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        setReceipts: setReceipts,
        setSelectedReceipt: setSelectedReceipt
    }, dispatch);
};

const componentStyles = {
    alignTitle: {
        display: 'flex',
        marginTop: '4em',
        flexDirection: 'column'
    },
    title: {
        height: '27px',
        color: '#000000',
        fontSize: '22px',
        lineHeight: '0.91',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '1em',
        fontFamily: 'Montserrat'
    },
    subTitle: {
        padding: '5px',
        fontSize: '18px',
        color: '#000000',
        fontWeight: '500',
        lineHeight: '1.22',
        textAlign: 'center',
        alignSelf: 'center',
        fontFamily: 'Montserrat',
    }
};

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const Notas = (props) => {

    const [loadingReceipts, setLoadingReceipts] = useState(false);

    const query = useQuery();

    const token = query.get('token');

    localStorage.setItem('clientToken', `Bearer ${token}`);
    localStorage.setItem('clientTokenExpiration', formatISO(addHours(new Date(), 1)));

    useEffect(() => {
        fetchReceipts();
    }, []);

    const fetchReceipts = () => {
        setLoadingReceipts(true);
        getReceipts().then(
            (response) => {
                props.setReceipts(response.data);
                setLoadingReceipts(false);
            }
        ).catch(
            (err) => {
                console.log(err);
                setLoadingReceipts(false);
            }
        );
    };

    const handleItemExpand = (itemKey) => {
        const { receipts } = props;
        receipts.map((item, key) => {
            if (itemKey === key) {
                item.expanded = !!item.expanded;
                props.setSelectedReceipt(item);
            } else {
                item.expanded = false;
            }
        });
        props.setReceipts(receipts);
    };

    const handleItemClose = () => {
        const { receipts } = props;
        props.setSelectedReceipt(null);
        receipts.map((item) => {
            item.expanded = false;
        });
        props.setReceipts(receipts);
    };

    const statusIcon = (status) => {
        switch (status) {
        case 'Pendente':
            return (
                <FontAwesomeIcon
                    icon={faCircle}
                    className="status-icon icon-pending"
                />);
        case 'Emitida':
            return (
                <FontAwesomeIcon
                    icon={faCheck}
                    className="status-icon icon-confirmed"
                />);
        case 'Processando':
            return (
                <FontAwesomeIcon
                    icon={faSquare}
                    className="status-icon icon-processing"
                />);
        case 'Negada':
            return (
                <FontAwesomeIcon
                    icon={faTimes}
                    className="status-icon icon-error"
                />);
        default:
            return (
                <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="status-icon icon-processing"
                />);
        }
    };

    return(
        <div>
            <div style={componentStyles.alignTitle}>
                <span style={componentStyles.title}>
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        className={'head-icon'}
                        onClick={() => props.handleStepBack(0)}
                    />
                    Minhas notas
                </span>
                <span style={componentStyles.subTitle}>
                    Escolha quantas notas precisará emitir e faça todo o processo de forma simples.
                    Pague usando cartão de crédito ou boleto bancário!
                </span>
            </div>

            <div className="minhas-notas" style={{height: 'auto', minHeight: '42em'}}>
                {/*<div className="row filters">*/}
                {/*    <div className="col-md-4"/>*/}
                {/*    <div className="col-md-4" style={{textAlign: '-webkit-center', marginTop: '2.5em'}}>*/}
                {/*        <button className="filter-button" id="filterButton">*/}
                {/*            <span className="filter-button-text"> Filtros </span>*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-4" style={{textAlign: '-webkit-center', marginTop: '2.5em'}}>*/}
                {/*        <div style={{width: '100px', float: 'left'}}>*/}
                {/*            <select className="form-custom-select" defaultValue={5}>*/}
                {/*                <option value={5}>5</option>*/}
                {/*                <option value={10}>10</option>*/}
                {/*                <option value={15}>15</option>*/}
                {/*                <option value={20}>20</option>*/}
                {/*            </select>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {(loadingReceipts) && (
                    <div className={'loading-content'}>
                        <LoaderSpinner
                            width={120}
                            height={120}
                            color="#e1173f"
                            type="TailSpin"
                            className={'loading-spinner'}
                        />
                    </div>
                )}

                {(!loadingReceipts && props.receipts.length === 0) &&
                    (<div className="align-text">
                        <span className="custom-text">Você ainda não emitiu nenhuma nota.</span>
                        <span className="custom-text">Clique no botão abaixo e emita sua primeira nota.</span>
                        <div className="continue-button confirm-filter">
                            <span className="confirm-filter-text" onClick={() => props.handleStepBack(1)}>Emitir nota</span>
                        </div>
                    </div>)
                }


                {(!loadingReceipts && props.receipts.length > 0) && (
                    <div className="notas-tables mobile-table">
                        <div className="align-table">
                            <div className="div-table">
                                {
                                    props.receipts.map((item, key) => {

                                        const createdAt = item.data_criacao !== '' ?
                                            new Intl.DateTimeFormat('pt-BR')
                                                .format(new Date(item.data_criacao)) :
                                            '-';

                                        const updatedAt = item.data_ultima_alteracao !== '' ?
                                            new Intl.DateTimeFormat('pt-BR')
                                                .format(new Date(item.data_ultima_alteracao)) :
                                            '-';

                                        return (
                                            <div
                                                key={key}
                                                className={item.expanded ? 'div-table-row opened-row' : 'div-table-row'}
                                            >
                                                <div className="row-table">
                                                    <div className={'mobile-aligner'}>
                                                        <div className="div-table-col number-size">
                                                            {'Nº '}{item.numero !== '' ? item.numero : '-'}
                                                        </div>
                                                        <div className="div-table-col value-size">
                                                            {'Valor '}{
                                                                item.valor_total !== '' ?
                                                                    new Intl.NumberFormat('pt-BR', {
                                                                        style: 'currency',
                                                                        currency: 'BRL'
                                                                    }).format(item.valor_total) :
                                                                    '-'
                                                            }
                                                        </div>
                                                        <div className="div-table-col date-size">
                                                            {'Data '}{createdAt}
                                                        </div>
                                                    </div>
                                                    <div className="div-table-col">
                                                        {(!item.expanded) ? (
                                                            <FontAwesomeIcon
                                                                onClick={() => {
                                                                    item.expanded = !item.expanded;
                                                                    handleItemExpand(key);
                                                                }}
                                                                className={'action-icon'}
                                                                icon={item.expanded ? faMinusCircle : faPlusCircle}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                onClick={() => {
                                                                    item.expanded = !item.expanded;
                                                                    handleItemClose();
                                                                }}
                                                                className={'action-icon'}
                                                                icon={item.expanded ? faMinusCircle : faPlusCircle}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                {(item.expanded) && (
                                                    <div className="expanded-row">
                                                        <div className="line"/>
                                                        <span className="client-info">
                                                            {`Cliente: ${
                                                                item.tomador_nome !== '' ? 
                                                                    item.tomador_nome :
                                                                    item.tomador_email
                                                            }`}
                                                        </span>
                                                        <div className="buttons">
                                                            {   (item.permite_cancelar) &&
                                                                (<div className="cancel-button">
                                                                    <button className="no-decoration"
                                                                        id="cancelReceipt">Cancelar
                                                                    </button>
                                                                </div>)
                                                            }
                                                            <a
                                                                target={'_blank'}
                                                                className="open-button"
                                                                rel="noopener noreferrer"
                                                                href={item.filename_pdf}
                                                            >
                                                                <span>Abrir PDF</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );

                                    })
                                }
                            </div>
                        </div>
                    </div>
                )}

                {(!loadingReceipts && props.receipts.length > 0) && (
                    <div className="notas-tables desktop-table">
                        <div className="align-table">
                            <div className="div-table">
                                <div className="div-table-row-header">
                                    <div className="div-table-col-header" style={{marginLeft: '7.4em'}}>Nº</div>
                                    <div className="div-table-col-header item-header" style={{width: '10em'}}>CPF/CNPJ</div>
                                    <div className="div-table-col-header item-header">Valor</div>
                                    <div className="div-table-col-header item-header">Data</div>
                                    <div className="div-table-col-header item-header">Data final</div>
                                    <div className="div-table-col-header item-header">Status</div>
                                </div>
                                {
                                    props.receipts.map((item, key) => {

                                        const createdAt = item.data_criacao !== '' ?
                                            new Intl.DateTimeFormat('pt-BR')
                                                .format(new Date(item.data_criacao)) :
                                            '-';

                                        const updatedAt = item.data_ultima_alteracao !== '' ?
                                            new Intl.DateTimeFormat('pt-BR')
                                                .format(new Date(item.data_ultima_alteracao)) :
                                            '-';

                                        return (
                                            <div
                                                key={key}
                                                className={item.expanded ? 'div-table-row opened-row' : 'div-table-row'}
                                            >
                                                <div className="row-table">
                                                    <div className="div-table-col icon-width">
                                                        {statusIcon(item.status)}
                                                    </div>
                                                    <div className="div-table-col number-size">
                                                        {item.numero !== '' ? item.numero : '-'}
                                                    </div>
                                                    <div className="div-table-col cnpj-size">
                                                        {item.tomador_cpf_cnpj !== '' ? item.tomador_cpf_cnpj : '-'}
                                                    </div>
                                                    <div className="div-table-col value-size">
                                                        {
                                                            item.valor_total !== '' ?
                                                                new Intl.NumberFormat('pt-BR', {
                                                                    style: 'currency',
                                                                    currency: 'BRL'
                                                                }).format(item.valor_total) :
                                                                '-'
                                                        }
                                                    </div>
                                                    <div className="div-table-col date-size">
                                                        {createdAt}
                                                    </div>
                                                    <div className="div-table-col finalDate-size">
                                                        {updatedAt}
                                                    </div>
                                                    <div className="div-table-col status-size">
                                                        {item.status !== '' ? item.status : 'Indisponível'}
                                                    </div>
                                                    <div className="div-table-col">
                                                        {(!item.expanded) ? (
                                                            <FontAwesomeIcon
                                                                onClick={() => {
                                                                    item.expanded = !item.expanded;
                                                                    handleItemExpand(key);
                                                                }}
                                                                className={'action-icon'}
                                                                icon={item.expanded ? faMinusCircle : faPlusCircle}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                onClick={() => {
                                                                    item.expanded = !item.expanded;
                                                                    handleItemClose();
                                                                }}
                                                                className={'action-icon'}
                                                                icon={item.expanded ? faMinusCircle : faPlusCircle}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                {(item.expanded) && (
                                                    <div className="expanded-row">
                                                        <div className="line"/>
                                                        <span className="client-info">
                                                            {`Cliente: ${
                                                                item.tomador_nome !== '' ?
                                                                    item.tomador_nome :
                                                                    item.tomador_email
                                                            }`}
                                                        </span>
                                                        <div className="buttons">
                                                            {   (item.permite_cancelar) &&
                                                            (<div className="cancel-button">
                                                                <button className="no-decoration"
                                                                    id="cancelReceipt">Cancelar
                                                                </button>
                                                            </div>)
                                                            }
                                                            <a
                                                                target={'_blank'}
                                                                className="open-button"
                                                                rel="noopener noreferrer"
                                                                href={item.filename_pdf}
                                                            >
                                                                <span>Abrir PDF</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );

                                    })
                                }
                            </div>
                        </div>
                    </div>
                )}

                {/*<div className="row pagination">*/}
                {/*    <div className="col-md-12 pages">*/}
                {/*        <ul className="page-list">*/}
                {/*            <li className="pagination-item active-page">1</li>*/}
                {/*            <li className="pagination-item">2</li>*/}
                {/*            <li className="pagination-item">3</li>*/}
                {/*            <li className="pagination-item">4</li>*/}
                {/*            <li className="pagination-item">5</li>*/}
                {/*            <li className="pagination-item">6</li>*/}
                {/*        </ul>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Notas);
