import React, {useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from 'react-router-dom';
import ReactGA from "react-ga";
import UserFeedback from './utils/UserFeedBack';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';

import './App.scss';
import Header from './components/Header';
import Footer from './components/Footer';

import Home from './components/home';
// Componentes do "Emitir Notas"
import DadosEmpresa from './components/nota-fiscal/Dados-Empresa';
import DadosPessoa from './components/nota-fiscal/Dados-Pessoa';
import Credencial from './components/nota-fiscal/Credencial';
import DestinoNota from './components/nota-fiscal/Destino';
import Servico from './components/nota-fiscal/Servico';
import Valor from './components/nota-fiscal/Valor';
import Concluido from './components/nota-fiscal/Concluido';

import Invalid from './components/InvalidToken';
//Componentes "Minhas notas"
import LoadingData from './components/LoadingData';
import ReceiptList from './components/minhas-notas';
import {setInvalidToken} from "./actions/api-actions";

const history = createBrowserHistory();

history.listen((location) => {
    ReactGA.set({ page: location.pathname + location.hash });
    ReactGA.pageview(location.pathname + location.hash);
});

function App(props) {

    const [fetchUserData, setFetchUserData] = useState(false);

    const [feedBack, setFeedBack] = useState(null);

    const [currentStep, setCurrentStep] = useState(0);

    const [feedBackType, setFeedBackType] = useState('success');

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.hash);
        setFetchUserData(true);
    }, []);

    const handleNextStep = () => {
        setCurrentStep((currentStep + 1));
    };

    const handleStepBack = (customStep = false) => {
        setCurrentStep(customStep !== false ? customStep : (currentStep - 1));
    };

    const handleRequestError = (response) => {
        if (!response.response) {
            setFeedBackType('error');
            setFeedBack('Parece que você não está conectado 😥, por favor verifique sua conexão e tente novamente.');
        }
        else if (response.response.status === 401) {
            props.setInvalidToken(true);
        }
        else if (response.response.status === 500) {
            Sentry.captureException(response);
            setFeedBackType('error');
            setFeedBack('Ocorreu um erro inesperado, por favor recarregue a página e tente novamente.');
        }
        else {
            Sentry.captureException(response);
            setFeedBackType('error');
            setFeedBack((response.response.data) ? response.response.data :
                'Ocorreu um erro, por favor verifique sua conexão e tente novamente.');
        }
    };

    return (
        <div className="App">
            <Header
                handleStepBack={handleStepBack}
            />
            <UserFeedback
                message={feedBack}
                type={feedBackType}
            />
            {(props.invalidToken) ? (
                <Invalid />
            ) : (
                <Router>
                    <Switch>
                        <Route path={'/invalid'}>
                            <Invalid />
                        </Route>
                        <Route path={'/'}>
                            {(fetchUserData) && (
                                <LoadingData
                                    setCurrentStep={setCurrentStep}
                                    setFetchUserData={setFetchUserData}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                />
                            )}
                            {(!fetchUserData && currentStep === 0) && (
                                <Home
                                    handleNextStep={handleNextStep}
                                    handleStepBack={handleStepBack}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                    handleRequestError={handleRequestError}
                                />
                            )}
                            {(!fetchUserData && currentStep === 1) && (
                                <DadosEmpresa
                                    handleNextStep={handleNextStep}
                                    handleStepBack={handleStepBack}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                    handleRequestError={handleRequestError}
                                />
                            )}
                            {(!fetchUserData && currentStep === 2) && (
                                <DadosPessoa
                                    handleNextStep={handleNextStep}
                                    handleStepBack={handleStepBack}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                    handleRequestError={handleRequestError}
                                />
                            )}
                            {(!fetchUserData && currentStep === 3) && (
                                <Credencial
                                    handleNextStep={handleNextStep}
                                    handleStepBack={handleStepBack}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                    handleRequestError={handleRequestError}
                                />
                            )}
                            {(!fetchUserData && currentStep === 4) && (
                                <DestinoNota
                                    handleNextStep={handleNextStep}
                                    handleStepBack={handleStepBack}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                    handleRequestError={handleRequestError}
                                />
                            )}
                            {(!fetchUserData && currentStep === 5) && (
                                <Servico
                                    handleNextStep={handleNextStep}
                                    handleStepBack={handleStepBack}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                    handleRequestError={handleRequestError}
                                />
                            )}

                            {(!fetchUserData && currentStep === 6) && (
                                <Valor
                                    handleNextStep={handleNextStep}
                                    handleStepBack={handleStepBack}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                    handleRequestError={handleRequestError}
                                />
                            )}

                            {(!fetchUserData && currentStep === 7) && (
                                <Concluido
                                    handleStepBack={handleStepBack}
                                />
                            )}

                            {(!fetchUserData && currentStep === 8) && (
                                <ReceiptList
                                    handleNextStep={handleNextStep}
                                    handleStepBack={handleStepBack}
                                    setFeedBackMessage={setFeedBack}
                                    setFeedBackType={setFeedBackType}
                                    handleRequestError={handleRequestError}
                                />
                            )}
                        </Route>
                    </Switch>
                </Router>
            )}
            <Footer/>
        </div>
    );
}

export default App;
