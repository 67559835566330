import React, {useEffect} from 'react';
import ReactGA from 'react-ga';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import './style.css';
import {connect} from 'react-redux';
import App from '../../../App';
import {bindActionCreators} from 'redux';
import {setMethod} from '../../../actions/purchase-actions';
import {consumeCreditToken} from "../../../api";

const mapStateToProps = state => ({
    link: state.purchase.link,
    method: state.purchase.method
});

const mapActionsToProps = (dispatch, props) => {
    return bindActionCreators({
        setMethod: setMethod
    }, dispatch);
};

const Concluido = (props) => {

    useEffect(() => {
        ReactGA.event({
            category: 'Emissão Bradesco',
            action: 'Chegou na tela final',
            label: (props.method === 1) ? 'Boleto' : 'Cartão',
            nonInteraction: true
        });
        consumeCreditToken()
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            })
    }, [])

    return (
        <div className="content" style={{marginBottom: '40px'}}>
            <span className="head-title-success">
                {(props.method === 0) ? 'Solicitação concluída!' : 'Solicitação pendente.'}
            </span>

            <div className="success-check my-4">
                <FontAwesomeIcon icon={faCheck} className="custom-check-success"/>
            </div>

            <span className="header-sub-success mb-5">
                {(props.method === 0) ? '' +
                    'Sua nota está no processo de emissão. Enviaremos para seu email em alguns minutos.' :
                    'Sua nota está pendente. ' +
                    'O processo da emissão irá ser concluído após a confirmação do seu pagamento, ' +
                    'que poderá levar até 3 dias úteis.'}
            </span>

            <div className="footer-buttons-success">
                {(props.method === 1) ? (
                    <a
                        href={props.link}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        className="continue-button-bill"
                    >
                        <span className="custom-button-success">Abrir boleto</span>
                    </a>
                ) : ''}
                <div
                    className="continue-button-success"
                    onClick={() => props.handleStepBack(8)}
                >
                    <span className="custom-button-success">Ver minhas notas</span>
                </div>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapActionsToProps)(Concluido);
