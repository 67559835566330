import {
    SET_INVALID_TOKEN
} from '../actions/api-actions';

export default function apiReducer(state = '', {type, payload}) {
    if (type === SET_INVALID_TOKEN) {
        return {
            ...state,
            invalidToken: payload
        };
    } else {
        return state;
    }
}
